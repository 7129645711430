import React, { useMemo } from 'react'
import { pluralizeToString } from 'lib/string/pluralize'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE } from 'constants/offer'
import { MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE } from 'constants/content'
import getLPCRegionLOS from 'lib/offer/getLPCRegionLOS'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import LuxPlusPriceStack from 'luxPlus/components/LuxPlusPriceStack'
import { useOfferPackagePrice } from 'hooks/Offers/useOfferPriceNew'
import getOfferDurationText from 'lib/offer/getOfferDurationText'
import useOffersBestPrices from 'hooks/useOfferBestPrices'
import { EmptyObject } from 'lib/object/objectUtils'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { useAppSelector } from 'hooks/reduxHooks'

interface Props {
  offer: App.Offer | App.OfferSummary;
  variant: 'default' | 'condensed';
  selectedDatesAndRoomsFilter?: App.OfferListFilters;
}

function LEOfferPricing(props: Props) {
  const {
    offer,
    variant = 'default',
    selectedDatesAndRoomsFilter = EmptyObject,
  } = props
  const canViewLuxPlusBenefits = useAppSelector(checkCanViewLuxPlusBenefits)
  const bestPkg = useOffersBestPrices(offer, selectedDatesAndRoomsFilter)
  const pkg = bestPkg?.bestPricePackage ?? offer.lowestPricePackage!
  const {
    default: {
      saleUnit,
      price,
      value,
      discountPercent,
    },
    member: memberPricing,
  } = useOfferPackagePrice(pkg, offer)

  const valueToDisplay = canViewLuxPlusBenefits && memberPricing?.value ? memberPricing.value : value
  const discountToDisplay = canViewLuxPlusBenefits && memberPricing ? memberPricing.discountPercent : discountPercent

  const shouldShowRate = !offer.isDiscountPillHidden && discountPercent >= MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE

  const shouldShowDiscountPercent = offer.type === OFFER_TYPE_LAST_MINUTE
  const shouldShowValue = pkg.value > 0 && price && value > price && pkg.shouldDisplayValue &&
    !shouldShowDiscountPercent && (offer.type == OFFER_TYPE_HOTEL || !pkg.roomRate?.inclusionsHideValue)

  let staysFrom = offer.minDuration
  const LPCRegionLOS = getLPCRegionLOS(offer)
  if (LPCRegionLOS && offer.packages.some(p => p.duration === LPCRegionLOS)) {
    staysFrom = LPCRegionLOS
  }
  const showStaysFromLabel = staysFrom && offer.type === OFFER_TYPE_ALWAYS_ON && LPCRegionLOS && staysFrom < pkg.duration
  const durationText = useMemo(() => getOfferDurationText(offer, pkg), [offer, pkg])

  return (<>
    <PriceRowPriceCaption>{durationText}</PriceRowPriceCaption>
    <LuxPlusPriceStack
      price={price}
      memberPrice={memberPricing?.price}
      size="L"
      saleUnit={saleUnit}
      loyaltyConfig={offer.luxPlus}
    />
    {shouldShowValue && <PriceRowValueDiscountWithCaption
      size="L"
      originalValue={Math.ceil(valueToDisplay)}
      discountPercentage={shouldShowRate ? discountToDisplay : undefined}
      data-testid="le-offer-pricing"
    />}
    {staysFrom && showStaysFromLabel && <PriceRowCaption>
      Stays from {pluralizeToString('night', staysFrom)} available
    </PriceRowCaption>}
    {!!pkg.roomOccupancy && offer.type === 'tour' && <PriceRowCaption>
      {pkg.roomOccupancy}
    </PriceRowCaption>}
    {variant === 'default' && <PriceRowTaxesAndFees />}
  </>)
}

export default LEOfferPricing
