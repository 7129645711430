import PriceStackLoyaltyProminentPrice from 'components/Luxkit/PricePoints/Loyalty/PriceStackLoyaltyProminentPrice'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import React, { ComponentProps, useMemo } from 'react'
import { useAppSelector } from 'hooks/reduxHooks'
import { checkCanViewLuxPlusBenefits, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import PriceStackPriceWithLoyalty from 'components/Luxkit/PricePoints/PriceStackPriceWithLoyalty'
import PriceRowLoyaltyPriceBox from 'components/Luxkit/PricePoints/Loyalty/PriceRowLoyaltyPriceBox'
import { isLuxPlusToursEnabled } from 'checkout/selectors/tourV2Selectors'

type InheritedPriceRowPriceProps = Pick<ComponentProps<typeof PriceRowPrice>, 'signDisplay' | 'size'>
type InheritedPriceStackPriceWithLoyaltyProps = Pick<ComponentProps<typeof PriceStackPriceWithLoyalty>, 'horizontalAlign' | 'desktopHorizontalAlign'>

interface CommonProps extends InheritedPriceRowPriceProps, InheritedPriceStackPriceWithLoyaltyProps {
  testid?: string;
  /**
   * @default vertical
   */
  orientation?: ComponentProps<typeof PriceStackLoyaltyProminentPrice>['orientation']
  loyaltyConfig?: App.OfferLuxPlusConfig;
  isTour?: boolean;
  mode?: App.LuxPlusPriceStackMode;
}

interface PropsWithSeparatePriceFields extends CommonProps {
  price: number;
  saleUnit: string;
  memberPrice?: number;
}

interface PropsWithPricingField extends CommonProps {
  pricing: App.Pricing;
  memberPricing?: App.Pricing;
}

type Props = PropsWithSeparatePriceFields | PropsWithPricingField

function LuxPlusPriceStack(props: Props) {
  const {
    size,
    testid,
    signDisplay,
    horizontalAlign = 'start',
    desktopHorizontalAlign,
    orientation = 'vertical',
    loyaltyConfig,
    isTour = false,
    mode,
  } = props

  let memberPrice: number = 0
  let price: number
  let saleUnit: string

  if ('pricing' in props) {
    price = props.pricing.price
    saleUnit = props.pricing.saleUnit
    memberPrice = props.memberPricing?.price || 0
  } else {
    price = props.price
    saleUnit = props.saleUnit
    memberPrice = props.memberPrice || 0
  }

  const canViewLuxPlusBenefits = useAppSelector(checkCanViewLuxPlusBenefits)
  const luxPlusEnabled = useAppSelector(isLuxPlusEnabled)
  const luxPlusTourEnabled = useAppSelector(isLuxPlusToursEnabled)
  const displayedMode = useMemo(() => {
    if (!luxPlusEnabled) {
      return 'regular-only'
    } else if (mode) {
      return mode
    } else if (!!isTour && !luxPlusTourEnabled) {
      // This case will be ignored for all non-tours
      // For tours, this case will return below when toggle is off
      // if toggle is on, this case is ignored and regular logic (in the following cases) is used to display
      // the component appropriately
      return 'regular-only'
    } else if (!canViewLuxPlusBenefits && memberPrice > 0 && memberPrice < price) {
      return 'regular-prominent'
    } else if (loyaltyConfig?.access === 'memberOnly') {
      return 'member-only'
    } else if (canViewLuxPlusBenefits && memberPrice > 0 && memberPrice < price) {
      return 'member-prominent'
    } else {
      return 'regular-only'
    }
  }, [canViewLuxPlusBenefits, isTour, loyaltyConfig?.access, luxPlusEnabled, luxPlusTourEnabled, memberPrice, price, mode])

  switch (displayedMode) {
    case 'member-only':
      return <PriceRowLoyaltyPriceBox
        data-testid={testid}
        memberPrice={memberPrice || price}
        saleUnit={saleUnit}
        size={size}
        horizontalAlign={horizontalAlign}
      />
    case 'regular-only':
      return <PriceRowPrice
        data-testid={testid}
        price={price}
        saleUnit={saleUnit}
        size={size}
        signDisplay={signDisplay}
      />
    case 'member-prominent':
      return <PriceStackLoyaltyProminentPrice
        data-testid={testid}
        memberPrice={memberPrice}
        saleUnit={saleUnit}
        nonMemberPrice={price}
        size={size}
        memberPriceSignDisplay={signDisplay}
        nonMemberPriceSignDisplay={signDisplay}
        orientation={orientation}
        horizontalAlign={horizontalAlign}
        desktopHorizontalAlign={desktopHorizontalAlign}
      />
    case 'regular-prominent':
      return <PriceStackPriceWithLoyalty
        data-testid={testid}
        memberPrice={memberPrice}
        saleUnit={saleUnit}
        nonMemberPrice={price}
        size={size}
        signDisplay={signDisplay}
        horizontalAlign={horizontalAlign}
        desktopHorizontalAlign={desktopHorizontalAlign}
      />
    case 'member-checkout-total':
      return <PriceRowLoyaltyPriceBox
        data-testid={testid}
        memberPrice={memberPrice || price}
        saleUnit={saleUnit}
        size={size}
        horizontalAlign={horizontalAlign}
      />
  }
}

export default LuxPlusPriceStack
