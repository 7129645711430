import React, { useCallback, ComponentProps } from 'react'
import Label from 'components/Luxkit/Label/Label'
import styled from 'styled-components'
import Caption from 'components/Luxkit/Typography/Caption'
import LuxPlusLogo from './Logos/LuxPlusLogo'
import LineInfoCircleIcon from 'components/Luxkit/Icons/line/LineInfoCircleIcon'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import cn from 'clsx'
import { rem } from 'polished'
import Clickable from 'components/Common/Clickable'
import FormatCurrency from 'components/Common/FormatCurrency'
import { themeClassName } from 'lib/theme/themeUtils'
import { roundLuxPlusInclusionsValue } from 'luxPlus/lib/utils'
import { useAppSelector } from 'hooks/reduxHooks'
import LuxPlusInsuranceLabel from './LuxPlusInsuranceLabel'
import LuxPlusBookingProtectionLabelLabel from './LuxPlusBookingProtectionLabel'

const LabelElement = styled(Label)`
  background-color: ${props => props.theme.palette.product.loyalty.background};
  color: ${props => props.theme.palette.product.loyalty.contrast};
  border-color: ${props => props.theme.palette.product.loyalty.background} !important;

  &.size-small {
    height: ${rem(20)};
  }
`

interface Props extends Pick<ComponentProps<typeof Clickable>, 'to'> {
  onClick?: () => void;
  type: App.LuxPlusLabelType;
  size?: 'small' | 'medium';
  saveAmount?: number;
  hideSaveAmountPlus?: boolean;
  memberInclusionsAmount?: number;
}

function LuxPlusLabel({ type, onClick, size = 'medium', saveAmount, hideSaveAmountPlus, to, memberInclusionsAmount = 0, ...rest }: Props) {
  const luxPlusEnabled = useAppSelector(isLuxPlusEnabled)
  const clickHandler = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (!onClick) return
    e.preventDefault()
    e.stopPropagation()
    onClick()
  }, [onClick])

  if (!luxPlusEnabled) return null

  const logoHeight = size === 'small' ? 8 : 10
  const saveAmountSuffix = hideSaveAmountPlus ? '' : '+'

  const label = <LabelElement
      className={cn(themeClassName('inverse'), `size-${size}`)}
      kind="tertiary"
      variant="grey"
      endIcon={onClick ? <LineInfoCircleIcon size="XS" /> : undefined}
      data-testid="lux-plus-label"
      {...rest}
    >
    {type === 'bonus-inclusions' && <>
      {memberInclusionsAmount > 0 && <><Caption variant="medium" weight="bold"><FormatCurrency format="dynamic" value={roundLuxPlusInclusionsValue(memberInclusionsAmount)} />+ of bonus inclusions with </Caption><LuxPlusLogo height={logoHeight} /></>}
      {!memberInclusionsAmount && <><Caption variant="medium" weight="bold">Bonus inclusions with</Caption><LuxPlusLogo height={logoHeight} /></>}
    </>}
    {type === 'member' && <><LuxPlusLogo height={logoHeight} /> <Caption variant="medium" weight="bold">member</Caption></>}
    {type === 'only-logo' && <LuxPlusLogo height={logoHeight} />}
    {type === 'early-access-offer' && <><Caption variant="medium" weight="bold">Early access with</Caption><LuxPlusLogo height={logoHeight}/></>}
    {type === 'member-only-offer' && <><LuxPlusLogo height={logoHeight} /><Caption variant="medium" weight="bold">member-only offer</Caption></>}
    {type === 'member-price-offer' && !!saveAmount && <><Caption variant="medium" weight="bold">Save <FormatCurrency format="dynamic" value={saveAmount} />{saveAmountSuffix} with</Caption><LuxPlusLogo height={logoHeight} /></>}
    {type === 'member-price-offer-tour' && !!saveAmount && <><Caption variant="medium" weight="bold">Save <FormatCurrency format="dynamic" value={saveAmount} />{saveAmountSuffix}/person with</Caption><LuxPlusLogo height={logoHeight} /></>}
    {type === 'options-available-tour' && <><LuxPlusLogo height={logoHeight} /><Caption variant="medium" weight="bold">options available</Caption></>}
    {type === 'plus' && <LuxPlusLogo type="plus-only" height={logoHeight} />}
    {type === 'plus-preview' && <><LuxPlusLogo type="plus-only" height={logoHeight} /><Caption variant="medium" weight="bold">preview</Caption></>}
    {type === 'insurance' && !!saveAmount && <LuxPlusInsuranceLabel saveAmount={saveAmount} logoHeight={logoHeight} />}
    {type === 'booking-protection' && !!saveAmount && <LuxPlusBookingProtectionLabelLabel saveAmount={saveAmount} logoHeight={logoHeight} />}
  </LabelElement>

  if (onClick || to) return <Clickable onClick={clickHandler} to={to} data-testid="lux-plus-label-clickable">{label}</Clickable>

  return label
}

export default LuxPlusLabel
