import React, { useContext, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { matchPath } from 'react-router'
import ModalContext from 'contexts/ModalContext'
import MasterModalContext from 'contexts/MasterModalContext'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyFeatureFlags } from 'constants/optimizely'
import EmailResubscribeModal from 'components/Account/EmailResubscribeModal'
import { fetchLESubscriptionsV2, fetchResubscribeModalDismissedAt } from 'actions/LESubscriptionsActions'

const ResubModalExcludedRoutes = [
  '/:regionCode/account',
  '/:regionCode/checkout',
  '/:regionCode/checkout-restore-cart',
  '/:regionCode/checkout-gateway',
]

const MODAL_OPENED_TIMEOUT_IN_SECONDS = 10

function useShowEmailResubscribeModal() {
  const [modalRecentlyOpened, setModalRecentlyOpened] = useState<boolean>(false)
  const [isDismissedRecently, setIsDismissedRecently] = useState<boolean>(false)
  const [loggedInPagesViewed, setLoggedInPagesViewed] = useState<Array<string>>([])
  const dispatch = useAppDispatch()
  const showModal = useContext(ModalContext)
  const modalState = useContext(MasterModalContext)

  const isEmailResubscribeModalEnabled = !!useOptimizelyExperiment(OptimizelyFeatureFlags.isEmailResubscribeModalEnabled)

  const userId = useAppSelector(state => state.auth.account.memberId)
  const currentPath = useAppSelector(state => state.router.location.pathname)
  const isEmailSubscribed = useAppSelector(state => state.leSubscriptions.settings.email_subscribed)
  const emailSubscriptionUpdatedAt = useAppSelector(state => state.leSubscriptions.emailSubscribedUpdatedAt)
  const resubscribeModalDismissedAt = useAppSelector(state => state.leSubscriptions.resubscribeDismissedAt)
  const fetchError = useAppSelector(state => state.leSubscriptions.fetchError)
  const isInvalidRoute = useMemo(() => !!matchPath(currentPath, { path: ResubModalExcludedRoutes, exact: false }), [currentPath])
  const hasViewedTwoPages = useMemo(() => !isInvalidRoute && loggedInPagesViewed.length >= 2, [isInvalidRoute, loggedInPagesViewed])

  // Fetch subscriptions V2 info
  useEffect(() => {
    if (userId && isEmailResubscribeModalEnabled) {
      dispatch(fetchLESubscriptionsV2(userId))
      dispatch(fetchResubscribeModalDismissedAt(userId))
    }
  }, [dispatch, isEmailResubscribeModalEnabled, userId])

  // Check subscription dismissed dates
  useEffect(() => {
    if (resubscribeModalDismissedAt && emailSubscriptionUpdatedAt) {
      const lastModalDismissedDate = moment.utc(resubscribeModalDismissedAt)
      const emailPreferenceUpdatedDate = moment.utc(emailSubscriptionUpdatedAt)

      const hasDisabledModalRecently = moment.utc().diff(lastModalDismissedDate, 'days') <= 90
      const hasDisabledPreferenceRecently = !isEmailSubscribed && moment.utc().diff(emailPreferenceUpdatedDate, 'days') <= 90

      setIsDismissedRecently(hasDisabledModalRecently || hasDisabledPreferenceRecently)
    }
  }, [emailSubscriptionUpdatedAt, isEmailSubscribed, resubscribeModalDismissedAt])

  useEffect(() => {
    if (userId && currentPath) {
      setLoggedInPagesViewed([...loggedInPagesViewed, currentPath])
    } else if (!userId && loggedInPagesViewed.length) {
      setLoggedInPagesViewed([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, currentPath])

  // Prevent to open over other modals
  useEffect(() => {
    let openOtherModalTimeout

    if (modalState.open) {
      setModalRecentlyOpened(true)
    }

    if (!modalState.open && modalRecentlyOpened) {
      openOtherModalTimeout = setTimeout(() => {
        setModalRecentlyOpened(false)
      }, MODAL_OPENED_TIMEOUT_IN_SECONDS * 1000)
    }

    return () => {
      clearTimeout(openOtherModalTimeout)
    }
  }, [modalState.open, modalRecentlyOpened])

  const userReSubscribable = !fetchError && !isEmailSubscribed && isEmailResubscribeModalEnabled
  const triggerDisplay = !isDismissedRecently && !modalRecentlyOpened && !modalState.open && hasViewedTwoPages

  useEffect(() => {
    if (
      userReSubscribable &&
      userId &&
      triggerDisplay
    ) {
      showModal(<EmailResubscribeModal />)
    }
  }, [showModal, triggerDisplay, userId, userReSubscribable])
}

export default useShowEmailResubscribeModal
