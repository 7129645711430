import React, { useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'
import moment from 'moment'

import { connect } from 'react-redux'
import { encodeOfferIds } from 'lib/search/searchUtils'
import { ImageParams } from 'components/Common/ResponsiveImage'
import { isLPCTacticalEnabled } from 'lib/offer/offerUtils'
import { Location } from 'history'
import {
  Main,
  MainImageSegment,
  OfferOverlayContainer,
  StyledOfferBadge,
  DetailSegment,
  StyledCSSBreakpoint,
} from './SearchTileStyles'
import {
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_LAST_MINUTE,
  PRODUCT_TYPE_ULTRALUX,
} from 'constants/offer'
import { queryKeySelectedOfferIds } from '../../../../constants/url'
import { setSearchParamValue } from '../../../../lib/url/searchUrlUtils'
import { showUserReviewsRating } from '../../../../lib/order/reviewUtils'
import { useIsMobileScreen } from 'lib/web/deviceUtils'
import { useTacticalUpsellBanner } from 'hooks/useTacticalUpsellBanner'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import config from 'constants/config'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import FloatingBookmarkContainer from 'tripPlanner/components/Bookmark/Common/FloatingBookmarkContainer'
import Group from '../../../utils/Group'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import NumberRating from '../../../Common/NumberRating'
import OfferTileLocationSection from './TileSections/OfferTileLocationSection'
import OfferTilePropertyHeading from './TileSections/OfferTilePropertyHeading'
import ProductTypeLabel from '../../../Luxkit/Label/ProductTypeLabel'
import SearchOfferTileInclusionsLPCUpsellBanner from './TileSections/SearchOfferTileInclusionsLPCUpsellBanner'
import SearchOfferTileInclusionsUpsellBanner from './TileSections/SearchOfferTileInclusionsUpsellBanner'
import SearchTilePriceDetails from './TileSections/SearchTilePriceDetails'
import SearchTilePropertyDetails from './TileSections/SearchTilePropertyDetails'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import ViewBundleOffer from './ViewBundleOffer'

const UpsellWrapper = styled.div`
  grid-column: 1 / 3;
  margin-top: ${rem(8)};
  align-self: flex-end;
`

const StyledHeadingAndRating = styled(Group)`
  grid-row-start: 1;
  grid-column-start: span 2;
  height: min-content;
`

const imageParams: ImageParams = {
  tabletAspectRatio: '16:9',
  tabletWidth: '100vw',
  desktopAspectRatio: '16:9',
  desktopWidth: '67vw',
  largeDesktopAspectRatio: '16:9',
  largeDesktopWidth: '762px',
  quality: 'good',
}

interface MappedStateProps {
  location: Location;
  windowSearch: string;
  isSpoofed: boolean;
}

interface Props {
  offer: App.Offer | App.OfferSummary;
  passedWalledGarden: boolean;
  productClick?: (offer: App.Offer | App.OfferSummary) => void;
  offerMetaData?: App.OfferListMetaData;
  filters?: App.OfferListFilters;
  bestPrice: App.OfferAvailableRate;
  soldOut: boolean;
  bestPricePackage?: App.Package;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
  offerLinkIncludesFilters?: boolean;
  onImageChange?: (idx: number, image?: App.Image) => void;
  onImageLoad?: () => void;
  showInclusionUpsell: boolean;
}

function SearchOfferTileExpanded(props: Props & MappedStateProps) {
  const {
    offer,
    passedWalledGarden,
    filters,
    bestPrice,
    bestPricePackage,
    eagerLoadFirstImage,
    offerUrl,
    offerMetaData,
    offerLinkIncludesFilters,
    soldOut,
    location,
    windowSearch,
    isSpoofed,
    onImageChange,
    onImageLoad,
    showInclusionUpsell,
  } = props

  const shouldShowLPCTacticalUpsell = useTacticalUpsellBanner(offer, bestPricePackage, filters)
  const isMapPage = location.pathname.includes('/map')

  const isMobile = useIsMobileScreen()

  const { type, isPartnerProperty } = offer

  // This needs a memo because new moment objects aren't stable refs.
  const checkInDate = useMemo(() => {
    return filters?.checkIn ? moment(filters.checkIn) : undefined
  }, [filters?.checkIn])

  const locationName = [offer.locationHeading, offer.locationSubheading]
    .filter((t) => t)
    .join(', ')
  const cancellationPolicyType =
    bestPricePackage?.roomRate?.cancellationPolicy?.type

  const isUltraLux = offer.property?.isUltraLux ?? false

  const productType: App.ProductType = (() => {
    if (isUltraLux) {
      return PRODUCT_TYPE_ULTRALUX
    }

    if (type === OFFER_TYPE_LAST_MINUTE) {
      return type
    }

    if (isLPCTacticalEnabled() && offer.hasTactical) {
      return 'limited_time_special'
    }

    return OFFER_TYPE_ALWAYS_ON
  })()

  const showProductLabel = isSpoofed || productType !== OFFER_TYPE_ALWAYS_ON

  const mapSearchUrl = `/search/map?${setSearchParamValue(
    windowSearch,
    queryKeySelectedOfferIds,
    encodeOfferIds([offer]),
  )}`

  const { property } = offer

  return (
    <Main className={cn({ 'is-partner-property': isPartnerProperty, mapTile: isMapPage })}>
      <MainImageSegment>
        <ImageCarousel
          images={offer.images}
          imageParams={imageParams}
          eagerLoadFirstImage={eagerLoadFirstImage}
          onImageChange={onImageChange}
          onImageLoad={onImageLoad}
        />
        {type === OFFER_TYPE_ALWAYS_ON && (
          <OfferOverlayContainer>
            <ProductTypeLabel kind={showProductLabel ? 'default' : 'hidden'} productType={productType} />
            <TripGuard>
              <StyledCSSBreakpoint min="tablet">
                <FloatingBookmarkContainer className="slim-tile">
                  <BookmarkButton offer={offer} />
                </FloatingBookmarkContainer>
              </StyledCSSBreakpoint>
            </TripGuard>
          </OfferOverlayContainer>
        )}
        {offer.badge && (
          <StyledOfferBadge
            badge={offer.badge}
            dimensions={{ width: 86, height: 86 }}
          />
        )}
        <CSSBreakpoint only="mobile">
          <FloatingBookmarkContainer className="slim-tile">
            <BookmarkButton offer={offer} />
          </FloatingBookmarkContainer>
        </CSSBreakpoint>
      </MainImageSegment>
      <div>
        <DetailSegment className={cn({ mapTile: isMapPage })}>
          <StyledHeadingAndRating direction="horizontal" verticalAlign="start" gap={16} horizontalAlign="space-between">
            <Group direction="vertical">
              <OfferTileLocationSection
                location={locationName}
                mapSearchUrl={mapSearchUrl}
                shouldShowMapLink
              />
              {property && <OfferTilePropertyHeading name={property.name} />}
            </Group>
            {property && showUserReviewsRating(property.rating, property.reviewsTotal) && (
              <>
                <CSSBreakpoint max="mobile">
                  <NumberRating rating={property?.rating ?? 0} total={property.reviewsTotal} variant="small" direction="horizontal-reverse" onlyNumber />
                </CSSBreakpoint>
                <CSSBreakpoint min="tablet">
                  <NumberRating rating={property.rating} total={property.reviewsTotal} variant="small" direction="horizontal-reverse" />
                </CSSBreakpoint>
              </>
            )}
          </StyledHeadingAndRating>
          <SearchTilePropertyDetails
            location={locationName}
            cancellationPolicyType={cancellationPolicyType}
            offer={offer}
            checkInDate={checkInDate}
            filters={filters}
            offerUrl={offerUrl}
            bestPricePackage={bestPricePackage}
            maxInclusions={isMobile ? 3 : 5}
            maxInclusionLines={isMobile ? 5 : 8}
            showInclusionUpsell={showInclusionUpsell}
          />
          <SearchTilePriceDetails
            offer={offer}
            soldOut={soldOut}
            offerUrl={offerUrl}
            bestPriceForDates={bestPrice}
            filters={filters}
            checkInDate={checkInDate}
            bestPricePackage={bestPricePackage}
            passedWalledGarden={passedWalledGarden}
          />
          {config.ENABLE_BUNDLE_AND_SAVE && offerMetaData?.bundleOfferId && (
            <UpsellWrapper>
              <ViewBundleOffer
                offer={offer}
                filters={filters}
                offerMetaData={offerMetaData}
                offerLinkIncludesFilters={offerLinkIncludesFilters}
              />
            </UpsellWrapper>
          )}
          {!offerMetaData?.bundleOfferId && showInclusionUpsell && !shouldShowLPCTacticalUpsell && (
            <UpsellWrapper>
              <SearchOfferTileInclusionsUpsellBanner
                offer={offer}
                filters={filters}
                newTile
              />
            </UpsellWrapper>
          )}
          {!offerMetaData?.bundleOfferId && shouldShowLPCTacticalUpsell && (
            <UpsellWrapper>
              <SearchOfferTileInclusionsLPCUpsellBanner
                offer={offer}
                bestPricePackage={bestPricePackage}
                filters={filters}
                newTile
              />
            </UpsellWrapper>
          )}
        </DetailSegment>
      </div>
    </Main>
  )
}

SearchOfferTileExpanded.defaultProps = {
  filters: {},
}

const mapStateToProps = (state: App.State): MappedStateProps => ({
  location: state.router.location,
  windowSearch: state.router.location.search,
  isSpoofed: state.auth?.account.isSpoofed,
})

export default connect(mapStateToProps)(SearchOfferTileExpanded)
