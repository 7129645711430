import Caption from 'components/Luxkit/Typography/Caption'
import React from 'react'
import LuxPlusLogo from './Logos/LuxPlusLogo'
import { getAvailableInsuranceBenefits } from 'luxPlus/selectors/benefits/insurance'
import { useAppSelector } from 'hooks/reduxHooks'
import FormatCurrency from 'components/Common/FormatCurrency'

interface Props {
  logoHeight: number
  saveAmount: number
}

function LuxPlusInsuranceLabel(props: Props) {
  const { logoHeight, saveAmount } = props
  const luxPlusInsuranceBenefitView = useAppSelector(getAvailableInsuranceBenefits)
  const showBookingProtectionPercentage = saveAmount < luxPlusInsuranceBenefitView.luxPlusDiscountValueDisplayThreshold
  const displayValue = showBookingProtectionPercentage ? `up to ${luxPlusInsuranceBenefitView.luxPlusDiscountPercentage}%` : <FormatCurrency value={saveAmount} format="rounded" />
  return <><Caption variant="medium" weight="bold">Save {displayValue} with </Caption><LuxPlusLogo height={logoHeight} /></>
}

export default LuxPlusInsuranceLabel
