import {
  STEP_DEPARTING_FLIGHT,
  STEP_RETURNING_FLIGHT,
  STEP_MONTH_CALENDAR,
  STEP_DAY_CALENDAR,
  STEP_CAPACITY,
  STEP_CHANGE_DATES,
  STEP_CHANGE_PACKAGE,
  STEP_TOUR_DATES,
  STEP_EXPERIENCES,
  STEP_PAYMENT,
  STEP_BALANCE_PAYMENT,
  STEP_INSTALMENT_PAYMENT,
  STEP_INSTALMENT_BALANCE_PAYMENT,
  STEP_RESERVE_FOR_ZERO_PAYMENT,
} from 'constants/booking'

export const CHECKOUT_HEADER_PATHS = [
  `/booking/${STEP_DEPARTING_FLIGHT}`,
  `/booking/${STEP_RETURNING_FLIGHT}`,
  `/booking/${STEP_MONTH_CALENDAR}`,
  `/booking/${STEP_DAY_CALENDAR}`,
  `/booking/${STEP_CAPACITY}`,
  `/booking/${STEP_CHANGE_DATES}`,
  `/booking/${STEP_CHANGE_PACKAGE}`,
  `/booking/${STEP_TOUR_DATES}`,
  `/booking/${STEP_EXPERIENCES}`,
  `/booking/${STEP_PAYMENT}`,
  `/booking/${STEP_BALANCE_PAYMENT}`,
  `/booking/${STEP_INSTALMENT_PAYMENT}`,
  `/booking/${STEP_INSTALMENT_BALANCE_PAYMENT}`,
  `/booking/${STEP_RESERVE_FOR_ZERO_PAYMENT}`,
  `/bb/booking/${STEP_PAYMENT}`,
  '/checkout/:cartId/:step',
  '/checkout/:step',
  '/checkout',
  '/checkout-restore-cart',
  '/checkout-gateway',
  '/travel-protection/quote',
  '/travel-protection/checkout',
  '/gift-cards/checkout',
  '/travel-protection/details',
  '/travel-protection/payment',
]
