import { getCheckoutInsuranceProducts, isInsuranceSupported } from 'checkout/selectors/request/insurance'
import { getInsuranceItemsView } from 'checkout/selectors/view/insurance'
import { sum } from 'lib/array/arrayUtils'
import createSelector from 'lib/web/createSelector'
import { isEnabledLuxPlusDiscountedInsurance } from 'luxPlus/selectors/featureToggle'

function getQuoteByProductId(quotes: Array<App.InsuranceQuote>, products: Array<App.InsuranceProduct>): Array<App.InsuranceQuote> {
  const productIds = new Set(products.map(product => product.id))
  return quotes.filter(quote => productIds.has(quote.productId))
}

function getMaxSavings(quotes: Array<App.InsuranceQuote>): number {
  let maxSavings = 0

  for (const quote of quotes) {
    if (!quote.luxPlusTotal) {
      return 0
    }

    const savings = quote.total - quote.luxPlusTotal
    if (savings > maxSavings) {
      maxSavings = savings
    }
  }

  return maxSavings
}

export const getInsuranceMemberSavings = createSelector(
  getInsuranceItemsView,
  (insuranceItemsView) => {
    if (!insuranceItemsView.hasRequiredData) {
      return {
        totalPrice: 0,
        totalMemberPrice: 0,
        totalMemberPriceSavings: 0,
      }
    }

    const totalPrice = sum(insuranceItemsView.data, item => item.price)
    const totalMemberPrice = sum(insuranceItemsView.data, item => item.memberPrice)
    const totalMemberPriceSavings = totalPrice - totalMemberPrice

    return {
      totalPrice,
      totalMemberPrice,
      totalMemberPriceSavings,
    }
  },
)

const getInsuranceQuoteData = createSelector(
  getCheckoutInsuranceProducts,
  (state: App.State) => state.insurance.quotes,
  (state: App.State) => state.insurance.fetchingQuotes,
  (state: App.State) => state.insurance.triedFetchingQuotes,
  (insuranceProduct, quotes, fetching, triedFetchingQuotes) => {
    const validQuotes = getQuoteByProductId(quotes, insuranceProduct.data)
    const firstQuote = validQuotes[0]
    return {
      luxPlusDiscountPercentage: firstQuote?.luxPlusDiscountPercentage ?? 0,
      luxPlusDiscountValueDisplayThreshold: firstQuote?.luxPlusDiscountValueDisplayThreshold ?? 0,
      maxSavings: getMaxSavings(validQuotes),
      fetching: !!(triedFetchingQuotes && fetching),
    }
  },
)

export const getAvailableInsuranceBenefits = createSelector(
  isEnabledLuxPlusDiscountedInsurance,
  isInsuranceSupported,
  getInsuranceQuoteData,
  getInsuranceMemberSavings,
  (
    isEnabledLuxPlusDiscountedInsurance,
    insuranceSupported,
    insuranceQuoteData,
    insuranceMemberSavings,
  ): App.Checkout.LuxPlusInsuranceBenefits => {
    const totalMemberPriceSavings = insuranceMemberSavings.totalMemberPriceSavings
    const totalPrice = insuranceMemberSavings.totalPrice
    const totalMemberPrice = insuranceMemberSavings.totalMemberPrice

    return {
      isAvailable: isEnabledLuxPlusDiscountedInsurance && insuranceSupported,
      fetching: insuranceQuoteData.fetching,
      luxPlusDiscountPercentage: insuranceQuoteData.luxPlusDiscountPercentage,
      luxPlusDiscountValueDisplayThreshold: insuranceQuoteData.luxPlusDiscountValueDisplayThreshold,
      totalMemberMaxSavings: insuranceQuoteData.maxSavings,
      totalMemberPriceSavings,
      totalPrice,
      totalMemberPrice,
    }
  },
)
