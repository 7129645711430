import React from 'react'
import moment from 'moment'
import config from 'constants/config'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import FloatingBookmarkContainer from 'tripPlanner/components/Bookmark/Common/FloatingBookmarkContainer'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import Caption from 'components/Luxkit/Typography/Caption'
import { connect } from 'react-redux'
import { LONG_MONTH_LONG_YEAR } from 'constants/dateFormats'
import ViewBundleOffer from './ViewBundleOffer'
import SearchTilePropertyDetails from './TileSections/SearchTilePropertyDetails'
import SearchTilePriceDetails from './TileSections/SearchTilePriceDetails'
import cn from 'clsx'
import { Main, MainImageSegment, OfferOverlayContainer, StyledOfferBadge, DetailSegment, StyledCSSBreakpoint, ViewBundleOfferWrapper } from './SearchTileStyles'
import { useIsMobileScreen } from 'lib/web/deviceUtils'
import { ImageParams } from 'components/Common/ResponsiveImage'
import { Location } from 'history'
import ProductTypeLabel from '../../../Luxkit/Label/ProductTypeLabel'
import Group from '../../../utils/Group'
import OfferTileLocationSection from './TileSections/OfferTileLocationSection'
import { queryKeySelectedOfferIds } from '../../../../constants/url'
import { setSearchParamValue } from '../../../../lib/url/searchUrlUtils'
import { encodeOfferIds } from 'lib/search/searchUtils'
import NumberRating from '../../../Common/NumberRating'
import OfferTilePropertyHeading from './TileSections/OfferTilePropertyHeading'
import styled from 'styled-components'
import CSSBreakpoint from '../../../utils/CSSBreakpoint'
import { showUserReviewsRating } from '../../../../lib/order/reviewUtils'
import useOfferAlternativeDate from '../../../../hooks/Offers/useOfferAlternativeDate'
import SearchAltDatesBanner from '../../../Search/Hotels/SearchAltDatesBanner'

const imageParams: ImageParams = {
  tabletAspectRatio: '16:9',
  tabletWidth: '100vw',
  desktopAspectRatio: '16:9',
  desktopWidth: '67vw',
  largeDesktopAspectRatio: '16:9',
  largeDesktopWidth: '762px',
  quality: 'good',
}

interface MappedProps {
  location: Location;
  windowSearch: string;
}

interface Props {
  offer: App.Offer | App.OfferSummary;
  offerMetaData?: App.OfferListMetaData;
  bestPriceForDates: App.OfferAvailableRate;
  fetchingPriceForDates: boolean
  soldOut: boolean
  filters?: App.OfferListFilters;
  bestPricePackage: App.Package;
  eagerLoadFirstImage?: boolean;
  offerUrl: string;
  offerLinkIncludesFilters?: boolean;
  onImageChange?: (idx: number, image?: App.Image) => void;
  onImageLoad?: () => void;
}

const StyledHeadingAndRating = styled(Group)`
  grid-row-start: 1;
  grid-column-start: span 2;
`

const StyledSearchAltDatesBanner = styled(SearchAltDatesBanner)`
  grid-column: span 2;
`

const StyledSearchTilePriceDetails = styled(SearchTilePriceDetails)`
`

function SearchFlashOfferExpanded(props: Props & MappedProps) {
  const {
    offer,
    bestPriceForDates,
    soldOut,
    filters,
    bestPricePackage,
    eagerLoadFirstImage,
    offerUrl,
    offerMetaData,
    offerLinkIncludesFilters,
    location,
    windowSearch,
    onImageChange,
    onImageLoad,
  } = props

  const alternativeDate = useOfferAlternativeDate(offer.id, 'le', filters ?? {})

  const isMapPage = location.pathname.includes('/map')

  const { locationHeading, locationSubheading, badge, images: offerImages } = offer
  const locationName = [locationHeading, locationSubheading].filter(t => t).join(', ')
  const isCruise = offer.holidayTypes?.includes('Cruises')

  const checkInDate = filters?.checkIn ? moment(filters.checkIn) : undefined
  const cancellationPolicyType = bestPricePackage?.roomRate?.cancellationPolicy?.type

  const isUltraLux = offer.property?.isUltraLux ?? false

  const isMobile = useIsMobileScreen()

  const images = offerImages

  const { property } = offer

  const mapSearchUrl = `/search/map?${setSearchParamValue(
    windowSearch,
    queryKeySelectedOfferIds,
    encodeOfferIds([offer]),
  )}`

  return (
    <Main className={cn({ mapTile: isMapPage })}>
      <MainImageSegment>
        <ImageCarousel
          images={images}
          eagerLoadFirstImage={eagerLoadFirstImage}
          imageParams={imageParams}
          onImageChange={onImageChange}
          onImageLoad={onImageLoad}
        />
        <OfferOverlayContainer>
          <ProductTypeLabel kind="default" productType={isUltraLux ? 'ultralux_hotel' : 'hotel'} />
          <TripGuard>
            <StyledCSSBreakpoint min="tablet">
              <FloatingBookmarkContainer className="slim-tile">
                <BookmarkButton offer={offer} />
              </FloatingBookmarkContainer>
            </StyledCSSBreakpoint>
          </TripGuard>
        </OfferOverlayContainer>
        {badge && <StyledOfferBadge badge={badge} dimensions={{ width: 86, height: 86 }} />}
        <StyledCSSBreakpoint only="mobile">
          <FloatingBookmarkContainer className="slim-tile">
            <BookmarkButton offer={offer} />
          </FloatingBookmarkContainer>
        </StyledCSSBreakpoint>
      </MainImageSegment>
      <div>
        <DetailSegment className={cn({ mapTile: isMapPage })}>
          <StyledHeadingAndRating direction="horizontal" gap={16} horizontalAlign="space-between">
            <Group direction="vertical">
              <OfferTileLocationSection
                location={locationName}
                mapSearchUrl={mapSearchUrl}
                shouldShowMapLink
              />
              {property && <OfferTilePropertyHeading name={property.name} />}
            </Group>
            {property && showUserReviewsRating(property.rating, property.reviewsTotal) && (
              <>
                <CSSBreakpoint max="mobile">
                  <NumberRating rating={property?.rating} total={property.reviewsTotal} variant="small" direction="horizontal-reverse" onlyNumber />
                </CSSBreakpoint>
                <CSSBreakpoint min="tablet">
                  <NumberRating rating={property.rating} total={property.reviewsTotal} variant="small" direction="horizontal-reverse" />
                </CSSBreakpoint>
              </>
            )}
          </StyledHeadingAndRating>
          <SearchTilePropertyDetails
            location={locationName}
            cancellationPolicyType={cancellationPolicyType}
            offer={offer}
            checkInDate={checkInDate}
            filters={filters}
            offerUrl={offerUrl}
            maxInclusions={isMobile ? 3 : 5}
            bestPricePackage={bestPricePackage}
            bestPriceForDates={bestPriceForDates}
            maxInclusionLines={8}
           />
          <StyledSearchTilePriceDetails
            offer={offer}
            soldOut={soldOut}
            offerUrl={offerUrl}
            bestPriceForDates={bestPriceForDates}
            filters={filters}
            checkInDate={checkInDate}
            bestPricePackage={bestPricePackage}
          />

          {isCruise && <Caption colour="primary" variant="large" weight="bold">
            Sailing until {moment(offer.travelToDate).format(LONG_MONTH_LONG_YEAR)}
          </Caption>}

          {config.ENABLE_BUNDLE_AND_SAVE && offerMetaData?.bundleOfferId && (
            <ViewBundleOfferWrapper>
              <ViewBundleOffer offer={offer} filters={filters} offerMetaData={offerMetaData} offerLinkIncludesFilters={offerLinkIncludesFilters} />
            </ViewBundleOfferWrapper>
          )}
          {!!alternativeDate && <StyledSearchAltDatesBanner checkIn={alternativeDate.checkIn} checkOut={alternativeDate.checkOut} />}
        </DetailSegment>
      </div>
    </Main>
  )
}

const mapStateToProps = (state: App.State): MappedProps => ({
  location: state.router.location,
  windowSearch: state.router.location.search,
})

export default connect(mapStateToProps)(SearchFlashOfferExpanded)
