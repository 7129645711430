import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getCategoryAndProductId } from 'analytics/snowplow/helpers/itemCategorisation'
import getOrderDataMemberPricingMetadata from './orderMemberPricingMetadata'
import { getOfferById } from 'api/offer'

async function getLeHotelOrderItems(state: App.State, order: App.Order) {
  return await Promise.all(order.items.map(async orderItem => {
    let fullOffer = state.offer.offers[orderItem.offerId]
    if (!fullOffer) {
      fullOffer = await getOfferById(orderItem.offerId, { region: order.regionCode, currentCurrency: order.currencyCode }) as App.Offer
    }
    const { categoryId, productId } = getCategoryAndProductId(orderItem.offer.parentType, fullOffer)
    const offerLeadPrice = order?.hasFlight ? fullOffer?.lowestPriceBundlePackage?.price : fullOffer?.lowestPricePackage?.price
    const geo = fullOffer?.property?.geoData
    const luxPlusMetaData = getOrderDataMemberPricingMetadata(orderItem)
    return comluxgroup.createItem_1_1_0(
      {
        offerId: orderItem.offerId,
        categoryId,
        productId,
        duration: orderItem.duration,
        travelStart: orderItem.reservation?.startDate,
        travelEnd: orderItem.reservation?.endDate,
        price: orderItem.total,
        offerLeadPrice,
        offerLeadDuration: fullOffer?.lowestPricePackage?.duration ? String(fullOffer?.lowestPricePackage?.duration) : undefined,
        offerLeadUnit: fullOffer?.saleUnit,
        propertyFees: orderItem.propertyFees,
        currency: order.currencyCode,
        destinationCity: geo?.locality,
        destinationCountry: geo?.country,
        destinationRegion: geo?.administrativeAreaLevel1,
        reservationType: orderItem.reservationType,
        itemId: orderItem.id,
        bookingNumber: order.number,
        orderId: order.id,
        metadata: luxPlusMetaData ? JSON.stringify(luxPlusMetaData) : undefined,
      },
    )
  }),
  )
}

export default getLeHotelOrderItems
