import { createListenerMiddleware } from '@reduxjs/toolkit'
import { waitForValue } from 'lib/promise/waitForValue'
import { isCheckoutReady } from 'checkout/selectors/view/generic'
// Create instance
export const analyticsListenerMiddleware = createListenerMiddleware()

const waitForCheckoutReady = waitForValue<boolean>()
export const checkoutStateIsReady = () => waitForCheckoutReady.awaitForValue()
analyticsListenerMiddleware.startListening({
  // Run on any change as checkout can be affected by many items in state
  predicate: (_, currentState: App.State, previousState: App.State) => {
    const readyNow = isCheckoutReady(currentState)
    const readyBefore = isCheckoutReady(previousState)
    return readyNow !== readyBefore
  },
  effect: async(_, listenerApi) => {
    const state = listenerApi.getState() as App.State
    if (isCheckoutReady(state)) {
      waitForCheckoutReady.resolveWithValue(true)
    } else {
      waitForCheckoutReady.resetAwaitedValue()
    }
  },
})
