export enum OptimizelyExperiments {
  searchPriceMapMarkers = 'search_price_map_markers',
  SearchFijiExpansion = 'search_fiji_expansion',
  paymentsFlightsMerchantFees = 'payments_flight_merchant_fees',
  tripExplore = 'trip_explore',
  paymentsBookingProtectionInternational = 'payments_booking_protection',
  searchMapSplitView = 'search_map_split_view',
  searchBroadSearchLocation = 'search_broad_search_location',
  searchFlightDeal = 'search_flight_deal',
  cruiseSearchNewAlgorithm = 'cruise_search_new_algorithm',
  searchMobileSlimUI = 'search_mobile_slim_ui',
  cruiseNewBookingFlow = 'cruise_new_cruisebank_booking_flow_may_2024',
  flightPreFillRecentSearch = 'flights_pre_fill_recent_search',
  isSupportAssistantEnabled = 'opex_support_assistant_customer_pool',
  paymentsHotelsDepositsAu = 'payments_hotels_deposits_au_v2',
  paymentsHotelsDepositsNonAu = 'payments_hotels_deposits_non_au',
  nonFlashNextAlgo = 'search_non_flash_next_algo',
  pricePerNight = 'cro_price_per_night_non_au_nz',
  lereSearchModel = 'lere_search_model',
  searchAltAvailability = 'search_alt_dates_availability',
  searchClusterMapPins = 'search_cluster_map_pins',
  newMobileHotelOfferPage = 'cro_app_style_hotel_offer_page_booking_flow',
  supportAssistantWidgetEnabled = 'opex_lesa_widget',
  expediaStyleLoginPrompt = 'expedia_style_login_prompt',
  luxPlusCheckoutSavings = 'lux_plus_checkout_savings', // This is for high savings
  emailChangeEnabled = 'opex_email_change',
  romanticLandingPage = 'romantic_properties_v1_thematic_landing_page_a_b_test',
  searchRecentSearchCarousel = 'search_recent_search_carousel',
  redDotOnSignUpEnabled = 'cro_sign_up_dot',
  ghaLandingPageEnabled = 'gha_landing_page',
  signUpModalTitleEnabled = 'cro_sign_up_modal_without_save_up_to_70',
  checkoutInsuranceUiEnabled = 'cro_insurance_ui',
  searchLowMpvHiding = 'search_low_mpv_hiding',
  walledGardenEuropeOffers = 'cro_walled_garden_europe_offers',
  appsflyerVariantsEnabled = 'cro_appsflyer_banner_variants',
}

export enum OptimizelyFeatureFlags {
  luxPlusFeatureToggle = 'luxplus_feature_toggle',
  luxPlusToursToggle = 'luxplus_tours_toggle',
  luxPlusDiscountedInsuranceBenefit = 'lux_plus_discounted_insurance_benefit',
  isGoCardlessEnabled = 'payments_go_cardless',
  allowPhoneNumberLogin = 'allow_phone_number_login',
  promoAppBanner = 'app_banner_targeted_delivery',
  CSAgentsAutoQuote = 'cro_cs_agents_auto_quote',
  tourOptionalExtras = 'tours_optional_extras',
  tourReviewsEnabled = 'cro_tour_reviews',
  toursRequestAgentCallbackEnabled = 'csx_tours_request_agent_callback',
  travelProtection = 'cro_travel_protection',
  cancellationOutsidePolicyEnabled = 'opex_cancellation_outside_refund_policy',
  tourOptionalExtraPostPurchase = 'tour_optional_extra_post_purchase',
  isBonusCreditEnabled = 'cro_cancellation_bonus_credit',
  isEmailResubscribeModalEnabled = 'cro_email_resubscribe_modal',
  isCruiseMultiCabinBookingEnabled = 'cruise_multi_cabin_booking',
  rpForExperienceEnabled = 'cro_rp_for_experience',
}
