import React, { useMemo } from 'react'
import moment from 'moment'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import Group from 'components/utils/Group'
import WalledContent from 'components/Common/WalledContent'
import OfferTileLoggedInButtons from 'components/OfferList/OfferListTiles/Support/OfferTileLoggedInButtons'
import config from 'constants/config'
import { pluralizeToString } from 'lib/string/pluralize'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import { useOfferRatePrice } from 'hooks/Offers/useOfferPriceNew'
import useBedbankRates from 'hooks/useBedbankRates'

interface Props {
  offer: App.BedbankOffer | App.BedbankOfferSummary | App.BedbankOfferWithRate;
  /**
   * @default start
   */
  horizontalAlign?: 'start' | 'center'
  loggedInButtonsAlign?: 'none' | 'center' | 'left' | 'right'
  variant?: 'default' | 'condensed';
  selectedDatesAndRoomsFilter?: App.OfferListFilters
}

function BedbankOfferPriceDetails(props: Props) {
  const {
    offer,
    loggedInButtonsAlign = 'none',
    horizontalAlign = 'start',
    variant = 'default',
    selectedDatesAndRoomsFilter,
  } = props
  const canUseDateFilter = !!selectedDatesAndRoomsFilter?.rooms && !!selectedDatesAndRoomsFilter?.checkIn && !!selectedDatesAndRoomsFilter?.checkOut
  const selectedDuration = canUseDateFilter ? moment(selectedDatesAndRoomsFilter.checkOut).diff(selectedDatesAndRoomsFilter.checkIn, 'days') : undefined

  const hotelNights = selectedDuration ?? offer.sell?.los ?? 0
  const durationText = `${pluralizeToString('night', hotelNights)} from`

  const [{ hotelOnlyRates }] = useBedbankRates(
    offer.id,
    selectedDatesAndRoomsFilter?.rooms,
    selectedDatesAndRoomsFilter?.checkIn,
    selectedDatesAndRoomsFilter?.checkOut,
    canUseDateFilter,
  )

  const { default: pricing } = useOfferRatePrice(offer, hotelOnlyRates[0])

  const offerLocations: Array<string> = useMemo(() => {
    const locations = [
      offer.property?.address.city,
      offer.property?.address.stateProvinceName || '',
      offer.property?.address.countryName]
      .filter(Boolean)

    return locations
  }, [offer])

  const wall = (
    <OfferTileLoggedInButtons
      signUpText="Sign up for free"
      align={loggedInButtonsAlign}
      fullWidth={false}
      mdFullWidth={horizontalAlign === 'center'}
    />
  )
  return <Group
    desktopHorizontalAlign={horizontalAlign}
    direction="vertical"
    gap={8}
  >
    {pricing && <WalledContent enforced={offer.walledGarden} hidePricing={offer.hidePricing} wall={wall}>
      <Group desktopHorizontalAlign={horizontalAlign} direction="vertical">
        <PriceRowPriceCaption>{durationText}</PriceRowPriceCaption>
        <PriceRowPrice
          size="L"
          price={pricing.price}
          saleUnit={pricing.saleUnit}
        />
        {pricing.discountPercent > 0 && <PriceRowValueDiscountWithCaption
          size="L"
          originalValue={Math.ceil(pricing.value)}
          discountPercentage={pricing.discountPercent > (config.displayDiscountThreshold.bedbank * 100) ? pricing.discountPercent : undefined}
        />}
        {variant === 'default' && <PriceRowTaxesAndFees />}
      </Group>
      <PriceRowAgentHubCommission
        size="L"
        productType={offer.productType}
        offerLocations={offerLocations}
        offerId={offer.id}
      />
    </WalledContent>}
  </Group>
}

export default BedbankOfferPriceDetails
