import { Order as OrderTypes } from '@luxuryescapes/contract-svc-order'
import {
  REFUND_BACK_TO_ORIGIN_COMMENT,
  REFUND_DUE_TO_CHANGE_OF_MIND_REASON,
  REFUND_TO_CREDIT_REASON,
  StripePaymentElementPaymentMethodType,
} from 'constants/payment'
import request, { authOptions } from 'api/requestUtils'
import * as OfferService from 'api/offer'
import qs from 'qs'
import { arrayToObject, unique } from 'lib/array/arrayUtils'
import uuidV4 from 'lib/string/uuidV4Utils'
import { API } from '@luxuryescapes/lib-types'
import asyncPoll from 'lib/promise/asyncPoll'
import delay from 'lib/utils/delay'
import { getBrandLabel } from 'lib/whitelabels/whitelabels'
import { creditMap, orderMap, paymentMap, refundMap, refundMetaMap, reservationMap } from './mappers/orderMap'
import config from 'constants/config'
import { isFulfilled } from 'lib/promise/promiseUtils'
import { getDepositDetails, getInstalmentDetails, getReserveForZeroDetails } from './payment'
import { OrderItemType } from 'constants/order'
import { instalmentDetailsMap } from './mappers/paymentMap'
import { addGTMEvent } from './googleTagManager'
import { refundEvent } from 'analytics/eventDefinitions'
import { REFUND_REASONS } from 'components/Common/OrderView/OrderItemSummaries/HotelItemSummary/HotelCancellationModal/HotelCancelationReason'
import { journeyV2Map } from './lib/journeyV2Map'
import { FareType } from 'constants/flight'

export type LegacyOrderItems = {
  _links: {
    self: {
      href: string,
    },
    payments: {
      href: string,
    },
  },
  id: string,
  number: string,
  transaction_key: string,
  currency_code: string,
  region_code: string,
  status: string,
  items: [],
  brand: 'scooponexperience',
  total: number,
  app_type: string,
  is_gift: boolean,
  gift_to: string,
  gift_from: string,
  gift_message: string,
  customer_email: string,
  customer_given_name: string,
  customer_surname: string,
  customer_full_name: string,
  customer_phone_prefix: string,
  customer_phone: string,
  created_at: string,
  updated_at: string,
  utm_source: string,
  utm_medium: string,
  utm_campaign: string,
  utm_content: string,
  utm_term: string,
  utm_ad: string,
  utm_adgroup: string,
  tracker_info: {
    payment_type: string,
  },
}

export function getBrandOrders(memberId: string): Promise<Array<App.Order>> {
  if (!memberId) {
    return Promise.resolve([])
  }

  const label = getBrandLabel(config.BRAND)
  if (!label) {
    return Promise.resolve([])
  }

  const brandOrderUri = `/api/orders?customer_id=${memberId}&status=!awaiting_purchase&per_page=50&without_brand=1&le_label=${label}`
  return request.get<App.ApiResponse<Array<OrderTypes.Order>>>(brandOrderUri, { credentials: 'include' }).then(response => response.result.map(orderMap))
}

/**
 * Use to detect if a customer has orders from the old www-ee-customer website
 */
export async function getLedLegacyOrders(memberId: string) {
  if (!memberId) {
    return {
      orders: [],
      legacyCutoffDate: null,
    }
  }

  const legacyOrdersPath = `/api/ee/orders?customer_id=${memberId}&old_led_orders=true&page=1`
  const response = await request.get<App.ApiResponse<Array<LegacyOrderItems>>>(legacyOrdersPath, { credentials: 'include' })

  return {
    orders: response.result,
    legacyCutoffDate: response.legacyCutoffDate,
  }
}

type OrderStats = {
  total_purchase_count: number;
};

export async function getUserOrderCount(memberId: string | undefined, access_token?: string) {
  if (!memberId) {
    return {
      total_purchase_count: 0,
    }
  }

  const OrdersPath = '/api/orders/count'
  const response = await request.get<App.ApiResponse<Array<OrderStats>>>(
    OrdersPath,
    authOptions(access_token),
  )
  return {
    total_purchase_count: response.total_purchase_count,
  }
}

interface GetOrderParams {
  page?: number;
  pageSize?: number;
  status?: 'upcoming';
  sort?: 'asc' | 'desc';
  itemType?: OrderItemType;
}

const MagicUsersThatKillUsEverySixMonths = new Set([
  // The userWithCredits we use for e2e tests is a single user that ends up with thousands of orders before we clear it out
  // This starts to cause slow downs as we stream orders in during the course of it's e2e runs
  // To prevent this, we're going to identify this user and cap the number of orders returned to prevent slow downs
  '8c9433b0-5781-4b86-8139-43543b823f48',
  // This is the test production account, people use this to test purchases in production and has thousands of orders
  'e78297cc-0b82-466d-9453-86ea9074f053',
  // Used by an admin portal E2E, seems to be making about 10k orders per month
  'b7d3bee6-121e-4a74-ba50-33b8261a1067',
])

export function getOrders(
  memberId: string,
  region: string,
  params: GetOrderParams = {},
) {
  if (!memberId) {
    return Promise.resolve({
      orders: [] as Array<App.Order>,
      total: 0,
    })
  }

  const query = qs.stringify({
    customer_id: memberId,
    status: '!awaiting_purchase',
    per_page: params.pageSize ?? 50,
    upcoming: params.status === 'upcoming',
    page: params.page,
    item_type: params.itemType,
    order_direction: params.sort,
    show_purchasing_customer_orders: true, // only active on LEBT
  })

  return request.get<App.ApiResponse<Array<OrderTypes.Order>>>(
    `/api/orders?${query}`,
    { credentials: 'include' },
  ).then(async response => {
    const orders = response.result.map(orderMap)
    const modifiedOrders = await updateOrderItemsBookByDate(orders, region)
    // cap the number of orders for our magic user with credits user
    const total = MagicUsersThatKillUsEverySixMonths.has(memberId) ? Math.min(modifiedOrders.length, 20) : (response as any).total
    return {
      orders: modifiedOrders,
      total,
    }
  })
}

async function updateOrderItemsBookByDate(
  orders: Array<App.Order>,
  region: string,
): Promise<Array<App.Order>> {
  const offerIds = unique(orders.flatMap(order => order.items).filter(item => !item.reservationMade || !item.reservation).map(item => item.offerId))
  const offerResponse = await Promise.allSettled(offerIds.map(offerId => OfferService.getOfferById(offerId, {
    region,
  })))
    .then(v => v.filter(isFulfilled).map(p => p.value)) as Array<App.Offer>

  const offers = arrayToObject(offerResponse, o => o.id)

  return orders.map(order => ({
    ...order,
    items: order.items.map(item => {
      const offer = offers[item.offerId]
      if (offer && (!item.reservationMade || !item.reservation) && item.offer?.bookByDate) {
        return {
          ...item,
          offer: {
            ...item.offer,
            bookByDate: offer.bookByDate || item.offer.bookByDate,
          },
        }
      }
      return item
    }),
  }))
}

export function getPayments(orderId: string) {
  return request.get<App.ApiResponse<Array<API.Payment.Payment>>>(
    `/api/payments?id_orders=${orderId}`,
    { credentials: 'include' },
  ).then((response) => {
    return response.result.map(paymentMap)
  })
}

export function getRefunds(orderId: string) {
  return request.get<App.ApiResponse<Array<OrderTypes.Refund>>>(
    `/api/orders/${orderId}/refunds`,
    { credentials: 'include' },
  ).then((response) => response.result.map(refundMap))
}

export function getRefundMeta(orderId: string) {
  return request.get<App.ApiResponse<Array<App.RefundMeta>>>(
    `/api/refund_meta/${orderId}`,
    { credentials: 'include' },
  ).then((response) => response.result.map(refundMetaMap)).catch((err) => err)
}

export function getOrderDatesRequest(orderId: string) {
  const datesRequestUri = `/api/orders/dates-request/order/${orderId}?activeOnly=true`
  return request.get<App.ApiResponse<Array<App.DatesRequest>>>(datesRequestUri, { credentials: 'include' }).then(response => {
    return response.result.map(datesRequest => datesRequestMap(datesRequest))
  })
}

function getReservationDetail(itemId: string, orderId: string) {
  const uri = `/api/orders/${orderId}/items/${itemId}/reservation`

  return request.get<App.ApiResponse<OrderTypes.Reservation>>(uri, { credentials: 'include' })
    .then((response) => ({
      itemId,
      reservation: reservationMap(response.result, itemId),
      error: undefined,
    }))
    .catch((err) => ({
      itemId,
      reservation: undefined,
      error: {
        status: err.status,
        message: 'Error fetching reservation details',
      },
    }))
}

function getAllReservationDetail(order: App.Order) {
  return Promise.all(
    order.items.filter(item => item.reservationMade)
      .map((item) =>
        getReservationDetail(item.id, order.id),
      ),
  )
}

export function getReservationDetailsForItems(
  items: Array<App.OrderItem>,
  orderId: string,
): Promise<Array<App.OrderReservation>> {
  const reservationPromises = items.map(item => getReservationDetail(item.id, orderId))
  return Promise.all(reservationPromises).then((results) => results.map(r => r.reservation).filter((res): res is App.OrderReservation => !!res))
}

export function createOrder(data, otpToken?: string) {
  const options = {
    credentials: 'include' as RequestCredentials,
    headers: {} as any,
  }

  if (otpToken) {
    options.headers.verification_token = otpToken
  }

  const uri = '/api/orders'

  return request.post<App.ApiResponse<OrderTypes.Order>, unknown>(uri, data, options)
    .then(async(response) => {
      const order = orderMap(response.result)
      const reservations = await getAllReservationDetail(order)
      const reservationsByItemId = arrayToObject(reservations, res => res.itemId)
      order.items = order.items.map(item => ({
        ...item,
        reservation: reservationsByItemId[item.id]?.reservation,
        reservationError: reservationsByItemId[item.id]?.error,
      }))
      return order
    })
}

export function finalizeOrder(orderId) {
  const uri = `/api/orders/${orderId}`

  return request.patch(uri, {
    op: 'finalize',
  }, { credentials: 'include' })
}

export function abandonOrder(orderId) {
  const uri = `/api/orders/${orderId}`

  return request.patch(uri, {
    op: 'abandon',
  }, { credentials: 'include' })
}

export function cancelOrderGiftingRequest(orderId) {
  const uri = `/api/orders/${orderId}`

  return request.patch(uri, {
    op: 'cancel_order_gift',
  }, { credentials: 'include' })
}

export function createItem(orderId, data) {
  const uri = `/api/orders/${orderId}/items`

  return request.post(uri, data, { credentials: 'include' })
}

export function changePackage(orderId, itemId, data) {
  const uri = `/api/orders/${orderId}/items/${itemId}`

  data.op = 'change_package'
  return request.put<App.ApiResponse<any>, unknown>(uri, data, { credentials: 'include' })
    .then((response) => reservationMap(response.result, itemId))
}

export function changeReservationDates(orderId, itemId, data) {
  const uri = `/api/orders/${orderId}/items/${itemId}`

  data.op = 'change_dates'
  return request.put<App.ApiResponse<any>, unknown>(uri, data, { credentials: 'include' })
    .then((response) => reservationMap(response.result, itemId))
}

export function changeTourDates(orderId, data) {
  return request.patch<App.ApiResponse<OrderTypes.Order>, unknown>(`/api/orders/${orderId}`,
    { ...data, op: 'change_dates' },
    { credentials: 'include' },
  ).then((response) => orderMap(response.result))
}

export function changeArrivalDetails(orderId, itemId, data) {
  const uri = `/api/orders/${orderId}/items/${itemId}`

  data.op = 'change_arrival_details'
  return request.put<App.ApiResponse<any>, unknown>(uri, data, { credentials: 'include' })
    .then(async() => {
      const order = await getOrderWithPaymentsAndReservations(orderId)
      return order
    },
    )
}

export function getOrderWithPaymentsAndReservations(id: string) {
  return getOrderById(id).then((order) => {
    return Promise.all([
      getPayments(order.id),
      getRefunds(order.id),
      getRefundMeta(order.id),
      getAllReservationDetail(order),
      getDepositDetails(order.id) as unknown as Promise<App.Deposit>,
      getInstalmentDetails(order.id),
      getReserveForZeroDetails(order.id) as unknown as Promise<App.ReserveForZeroDetails>,
    ]).then(([payments, refunds, refundMeta, reservations, depositDetails, instalmentDetails, reserveForZeroDetails]) => {
      const reservationsByItemId = arrayToObject(reservations, res => res.itemId)
      return {
        ...order,
        // manual payments are made in a way that they should not be shown to the user
        // as they are only done only for rectification purposes
        depositDetails,
        instalmentDetails: instalmentDetailsMap(instalmentDetails),
        reserveForZeroDetails,
        payments: payments.filter(p => p.type !== 'manual'),
        refunds: refunds.filter(refund => refund.amount !== 0),
        refundMeta,
        items: order.items.map(item => ({
          ...item,
          reservation: reservationsByItemId[item.id]?.reservation,
          reservationError: reservationsByItemId[item.id]?.error,
        })),
      }
    })
  })
}

export function getOrderBySubscriptionId(id: string) {
  return request.get(`/api/orders/subscription-items?subscription_period_id=${id}`, { credentials: 'include' }).then((result: any) => {
    if (result) {
      return getOrderWithPaymentsAndReservations(result.fk_order_id)
    }
  })
}

export function getOrderById(id: string) {
  return request.get<App.ApiResponse<OrderTypes.Order>>(`/api/orders/${id}`, { credentials: 'include' })
    .then(response => orderMap(response.result))
}

export function getPublicOrderNotes(id: string) {
  return request.get<App.ApiResponse<Array<App.OrderComment>>>(`/api/orders/${id}/notes/public`, { credentials: 'include' })
}

export async function downloadOrderNoteAttachment(orderId: string, noteId: string) {
  const response : Blob = await request.get(`/api/orders/${orderId}/notes/${noteId}/attachment`, { credentials: 'include' })
  return response
}

export function getOrderTransactionStatus(id, transactionKey) {
  const uri = `/api/orders/${id}/transaction/${transactionKey}`

  return request.get(uri, { credentials: 'include' })
}

export function convertOrderToBNBL(orderId, itemId) {
  const uri = `/api/orders/${orderId}/items/${itemId}`

  return request.put(uri, { op: 'remove_dates' }, { credentials: 'include' })
}

export function putTourOnHold(orderId) {
  const uri = `/api/orders/${orderId}`
  return request.patch<App.ApiResponse<OrderTypes.Order>, unknown>(uri, { op: 'put_tour_on_hold' }, { credentials: 'include' })
    .then((response) => orderMap(response.result))
}

export function requestPutTourOnHold(orderId) {
  const uri = `/api/orders/${orderId}`
  return request.patch<App.ApiResponse<OrderTypes.Order>, unknown>(uri, { op: 'request_put_tour_on_hold' }, { credentials: 'include' })
    .then((response) => orderMap(response.result))
}

export interface createDatesRequestParams {
  orderId: string,
  itemId: string,
  numberOfAdults: number,
  numberOfChildren: number,
  numberOfInfants: number,
  requestDate: string,
  isChangeDates: boolean,
}

export function postDatesRequest(body: createDatesRequestParams) {
  const uri = '/api/orders/dates-request'
  return request.post(uri, body, { credentials: 'include' })
}

interface RefundRoomParams {
  orderId: string;
  itemId: string;
  roomId?: string;
  transactionKey?: string;
  method: App.RefundMethod;
  reason: string;
  offerId: string;
  applyBonusCredit?: boolean;
  customerRefundReason?: {
    option?: string;
    text?: string;
  }
}

export function refundRoom(
  { orderId, itemId, roomId, method, reason, customerRefundReason, transactionKey = uuidV4(), offerId, applyBonusCredit }: RefundRoomParams,
): Promise<App.Order> {
  const uri = `/api/orders/${orderId}/items/${itemId}?room_id=${roomId}`
  let comment = REFUND_BACK_TO_ORIGIN_COMMENT
  if (customerRefundReason?.option) {
    const { option, text } = customerRefundReason
    if (text) {
      // Use 'Other' format for any option that has a free text comment
      comment = `Other - {${text}}`
    } else {
      comment = option
    }
  }

  const data: App.RefundRequestData = {
    op: 'refund',
    transaction_key: transactionKey,
    reason,
    comment,
    method,
    applyBonusCredit,
  }
  return request.put(uri, data, { credentials: 'include' }).then(async(res: App.ApiResponse) => {
    const order = await getOrderWithPaymentsAndReservations(orderId)
    if (res.message === 'accepted') {
      addGTMEvent(refundEvent(order, [offerId], method))
    }
    return order
  })
}

export interface RefundInsuranceParams {
  orderId: string;
  itemId: string;
  transactionKey?: string;
  method: App.RefundMethod;
}

export async function refundInsurance(
  { orderId, itemId, method, transactionKey = uuidV4() }: RefundInsuranceParams,
): Promise<App.Order> {
  const uri = `/api/orders/${orderId}/items/${itemId}`
  const data: App.RefundRequestData = {
    op: 'refund',
    transaction_key: transactionKey,
    reason: REFUND_DUE_TO_CHANGE_OF_MIND_REASON,
    comment: REFUND_BACK_TO_ORIGIN_COMMENT,
    method,
  }

  const refundResponse = await request.put<App.ApiResponse<null>, App.RefundRequestData>(uri, data, { credentials: 'include' })
  await delay(100)

  // when we call refundInsurance, svc-order creates a few jobs, enqueues them, and then send the response.
  // So sometimes we call getOrderWithPaymentsAndReservations but some of these jobs could not be finished yet.
  // When this happens we need to wait a few moments and call getOrderWithPaymentsAndReservations again.
  // This function does it
  const updatedOrder = await asyncPoll<App.Order>({
    validateFunction: async(order) => {
      const item = order.insuranceItems.find(updatedItem => updatedItem.id === itemId)
      return !!item && item.status === 'cancelled'
    },
    apiCall: () => getOrderWithPaymentsAndReservations(orderId),
    maxTime: 30000,
    pollInterval: 500,
  })

  if (refundResponse.message === 'accepted') {
    addGTMEvent(refundEvent(updatedOrder, [itemId], method))
  }

  // @ts-ignore
  return updatedOrder
}

export async function refundSubscriptionItem(
  orderId: string,
  itemId: string,
  method: App.RefundMethod,
  options: {
    reason?: string,
    comment?: string;
    transactionKey?: string;
  } = {},
) {
  const {
    transactionKey = uuidV4(),
    comment = REFUND_BACK_TO_ORIGIN_COMMENT,
    reason = 'Other',
  } = options
  const data: App.RefundRequestData = {
    op: 'refund',
    transaction_key: transactionKey,
    reason,
    comment,
    method,
  }

  return request.put(`/api/orders/${orderId}/items/${itemId}`, data, { credentials: 'include' }).then(async() => {
    // Sending a refund op only queues a job, we need to then poll until we see it be complete
    await asyncPoll({
      validateFunction: async(order: App.Order) => {
        const item = order.subscriptionItems.find(item => item.id === itemId)
        return item?.status === 'cancelled'
      },
      apiCall: () => getOrderById(orderId),
      maxTime: 30000,
      pollInterval: 500,
    })
  })
}

interface RefundAddonsParams {
  orderId: string;
  addonIds: Array<string>;
  method: App.RefundMethod;
}

export interface RefundBookingProtectionParams {
  orderId: string;
  itemId: string;
  transactionKey?: string;
  method: App.RefundMethod;
}

export async function refundBookingProtection(
  { orderId, itemId, method, transactionKey = uuidV4() }: RefundBookingProtectionParams,
): Promise<App.Order> {
  const uri = `/api/orders/${orderId}/items/${itemId}`
  const data: App.RefundRequestData = {
    op: 'refund',
    transaction_key: transactionKey,
    reason: REFUND_DUE_TO_CHANGE_OF_MIND_REASON,
    comment: REFUND_BACK_TO_ORIGIN_COMMENT,
    method,
  }

  const refundResponse = await request.put<App.ApiResponse<null>, App.RefundRequestData>(uri, data, { credentials: 'include' })
  await delay(100)

  // when we call refundBookingProtection, svc-order creates a few jobs, enqueues them, and then send the response.
  // So sometimes we call getOrderWithPaymentsAndReservations but some of these jobs could not be finished yet.
  // When this happens we need to wait a few moments and call getOrderWithPaymentsAndReservations again.
  // This function does it
  const updatedOrder = await asyncPoll<App.Order>({
    validateFunction: async(order) => {
      const item = order.bookingProtectionItems.find((updatedItem: App.OrderBookingProtectionItem) => updatedItem.id === itemId)
      return !!item && item.status === 'cancelled'
    },
    apiCall: () => getOrderWithPaymentsAndReservations(orderId),
    maxTime: 30000,
    pollInterval: 500,
  })

  if (refundResponse.message === 'accepted') {
    addGTMEvent(refundEvent(updatedOrder, [itemId], method))
  }

  return updatedOrder
}

export async function refundAddons(
  { orderId, addonIds, method }: RefundAddonsParams,
): Promise<App.Order> {
  const uri = `/api/orders/${orderId}/items`
  const data: App.RefundRequestData = {
    op: 'refund',
    items: addonIds.map(id => ({
      item_id: id,
      transaction_key: uuidV4(),
    })),
    reason: REFUND_TO_CREDIT_REASON,
    comment: REFUND_BACK_TO_ORIGIN_COMMENT,
    method,
  }

  const refundResponse = await request.patch<App.ApiResponse<null>, App.RefundRequestData>(uri, data, { credentials: 'include' })
  await delay(100)

  // when we call refundAddons, svc-order creates a few jobs, enqueues them, and then send the response.
  // So sometimes we call getOrderWithPaymentsAndReservations but some of these jobs could not be finished yet.
  // When this happens we need to wait a few moments and call getOrderWithPaymentsAndReservations again.
  // This function does it
  const updatedOrder = await asyncPoll<App.Order>({
    validateFunction: async(updatedOrder) => addonIds.every(addonId => {
      const updatedAddon =
        updatedOrder.addonItems.find(updatedAddon => updatedAddon.id === addonId) ||
        updatedOrder.experienceItems.find(updatedExperience => updatedExperience.id === addonId) ||
        updatedOrder.transferItems.find(updatedTransfer => updatedTransfer.id === addonId)

      return updatedAddon?.status === 'cancelled'
    }),
    apiCall: () => getOrderWithPaymentsAndReservations(orderId),
    maxTime: 30000,
    pollInterval: 500,
  })
  if (refundResponse.message === 'accepted') {
    addGTMEvent(refundEvent(updatedOrder, addonIds, method))
  }

  // @ts-ignore
  return updatedOrder
}

interface ServerRefundInfo {
  item_metadata: {
    charge_component_key: string;
    cash_amount: number;
    accounting_amount: number;
  };
  surcharge_metadata?: {
    charge_component_key: string;
    cash_amount: number;
    accounting_amount: number;
  };
  business_credit_refund_amount: number;
  merchant_fee_amount?: number;
  bonus_credit: number;
  insurance_metadata: {
    is_refundable: boolean;
    accounting_amount: number;
    cancel_insurance: boolean;
  };
}

export interface StripePaymentIntentPayload {
  amount: number;
  currency: string;
  metadata: {
    orderId: string;
    saveCard?: boolean;
    paymentMethod?: string;
  };
}

export interface StripeValidateBinPayload {
  orderId: string;
  paymentMethodId: string;
  isV2?: boolean;
}

export interface StripePaymentElementPaymentIntentPayload {
  amount: number;
  currency: string;
  paymentMethodType: StripePaymentElementPaymentMethodType;
  metadata: {
    orderId: string;
    isSavedCard: boolean;
    isDeposit: boolean;
    shouldUpdateDefaultPaymentMethod: boolean,
    isNewCard: boolean,
    shouldSaveCard: boolean,
    isDeferredPayment: boolean,
    isDepositBalance: boolean,
    instalmentPaymentType?: string,
    paymentMethodId?: string,
    productType: string,
    userAgent?: string,
    ipAddress?: string,
    shouldSaveCardOffSession?: boolean,
    region?: string,
    offSessionCardPurpose?:string,
  };
}

export interface StripePaymentElementSetupIntentPayload {
  currency: string;
  paymentMethodType: StripePaymentElementPaymentMethodType;
  metadata: {
    orderId: string;
    isSavedCard: boolean;
    isDeposit: boolean;
    shouldUpdateDefaultPaymentMethod: boolean,
    isNewCard: boolean,
    shouldSaveCard: boolean,
    isDeferredPayment: boolean,
    isDepositBalance: boolean,
    instalmentPaymentType?: string,
    paymentMethodId?: string,
    productType: string,
    shouldSaveCardOffSession?: boolean,
    region?: string,
    offSessionCardPurpose?:string,
  };
}

export interface BridgerPayCashierPayload {
  order_id: string;
  currency: string;
  country: string;
  amount: number
  first_name: string;
  last_name: string;
  phone: string;
  order_details?: BridgerPayCashierAdditionalData | {};
}

export interface BridgerPayCashierAdditionalData {
  event_dates?: Array<string>;
  departure_dates?: Array<string>;
  checkin_dates?: Array<string>;
  destinations?: Array<string>;
  customer_name?: string;
  passenger_names?: Array<string>
  flight_carrier_codes?: Array<string>
  items: Array<{
    name: string;
    quantity: number;
    amount: number;
    currency: string;
  }>
}

export function getPossibleRoomRefundTotal(orderId: string, itemId: string, roomId?: string) {
  const uri = `/api/orders/${orderId}/items/${itemId}/refund?room_id=${roomId}&refund_type=auto`

  return request.get<App.ApiResponse<ServerRefundInfo>>(uri, { credentials: 'include' }).then(response => {
    const result = response.result
    return {
      refundableAmount: result.item_metadata.accounting_amount + (result.surcharge_metadata?.accounting_amount || 0) + (result.merchant_fee_amount || 0),
      businessCreditRefundableAmount: result.business_credit_refund_amount || 0,
      refundableMerchantFeeAmount: result.merchant_fee_amount || 0,
      bonusCreditAmount: result.bonus_credit || 0,
      insuranceMetadata: {
        cancelInsurance: result.insurance_metadata.cancel_insurance,
        insuranceTotalAmount: result.insurance_metadata.accounting_amount,
        isRefundable: result.insurance_metadata.is_refundable,
      },
    }
  }).catch(() => {
    return {
      refundableAmount: 0,
      businessCreditRefundableAmount: 0,
      refundableMerchantFeeAmount: 0,
      bonusCreditAmount: 0,
      insuranceMetadata: {
        cancelInsurance: false,
        insuranceTotalAmount: 0,
        isRefundable: false,
      },
    }
  })
}

export function createLeCreditTransaction(data) {
  return request.post('/api/buy_with_credit/checkout', data, { credentials: 'include' })
}

export function createPayPalTransaction(data) {
  return request.post('/api/braintree/checkout', data, { credentials: 'include' })
}

export function getPayPalCredentials() {
  return request.get('/api/braintree/credentials', { credentials: 'include' })
}

export function getStripeCredentials(currency: string) {
  return request.get<App.ApiResponse<any>>(`/api/stripe/credentials?currency=${currency}`, { credentials: 'include' }).then(response => response.result)
}

export function getRazorpayCredentials() {
  return request.get<App.ApiResponse<any>>('/api/razorpay/credentials', { credentials: 'include' })
}

export function getBridgerPayCashier(data: BridgerPayCashierPayload, paymentType: string) {
  return request.post<App.ApiResponse<any>, any>(`/api/bridgerpay/cashier?payment_type=${paymentType}`, data, { credentials: 'include' }).then(response => response.result)
}

export function getStripePaymentIntentSecret(data: StripePaymentIntentPayload) {
  return request.post<App.ApiResponse<any>, any>('/api/stripe/payment-intent/create', data, { credentials: 'include' }).then(response => response.result)
}

export function getStripeSetupIntentSecret(data: StripePaymentIntentPayload) {
  return request.post<App.ApiResponse<any>, any>('/api/stripe/setup-intent/deferred-payment', data, { credentials: 'include' }).then(response => response.result)
}

export async function stripeValidateBin(data: StripeValidateBinPayload) {
  return request.post<App.ApiResponse<void>, StripeValidateBinPayload>('/api/payments/v2/stripe/validate-bin', data, { credentials: 'include' }).then(response => response.result)
}

interface StripePaymentElementSetupIntentResponse {
  clientSecret?: string,
  ephemeralKey: string,
  customerID?: string,
  ephemeralKeySecret: string,
}

interface StripePaymentElementPaymentIntentResponse {
  clientSecret: string,
}

export function getStripePaymentElementPaymentIntentSecret(data: StripePaymentElementPaymentIntentPayload) {
  return request.post<App.ApiResponse<StripePaymentElementPaymentIntentResponse>, StripePaymentElementPaymentIntentPayload>('/api/payments/v2/stripe/payment-intents', data, { credentials: 'include' }).then(response => response.result)
}

export function getStripePaymentElementSetupIntentSecret(data: StripePaymentElementSetupIntentPayload) {
  return request.post<App.ApiResponse<StripePaymentElementSetupIntentResponse>, StripePaymentElementSetupIntentPayload>('/api/payments/v2/stripe/setup-intents', data, { credentials: 'include' }).then(response => response.result)
}

export interface KlarnaPayloadClient {
  attachment: {
    body: string;
  },
  locale: string;
  purchaseCountry: string;
  purchaseCurrency: string;
  // billingAddress: {
  //   email: string;
  //   familyName: string;
  //   givenName: string;
  //   phone?: string;
  // },
  customer: {
    dateOfBirth?: string;
  },
  orderAmount: number;
  orderTaxAmount: number;
  orderLines: Array<{
    type: string;
    reference: string;
    name: string;
    quantity: number;
    unitPrice: number;
    taxRate: number;
    totalAmount: number;
    totalDiscountAmount: number;
    totalTaxAmount: number;
    productUrl: string;
    imageUrl: string;
  }>;
}

export interface KlarnaPayloadServer {
  attachment: {
    body: string;
  },
  locale: string;
  purchase_country: string;
  purchase_currency: string;
  // billing_address: {
  //   email: string;
  //   family_name: string;
  //   given_name: string;
  //   phone?: string;
  // }
  customer: {
    date_of_birth?: string;
  },
  order_amount: number;
  order_tax_amount: number;
  order_lines: Array<{
    type: string;
    reference: string;
    name: string;
    quantity: number;
    unit_price: number;
    tax_rate: number;
    total_amount: number;
    total_discount_amount: number;
    total_tax_amount: number;
    product_url: string;
    image_url: string;
  }>;
}

export interface KlarnaCredentials {
  client_token: string;
  session_id: string;
  payment_method_categories: Array<{
    identifier: string;
    name: string;
    asset_urls: {
      descriptive: string;
      standard: string;
    }
  }>
}

export interface KlarnaSession {
  clientToken: string;
  paymentMethod: string;
  id: string;
}

export function createKlarnaSession(data: KlarnaPayloadClient): Promise<KlarnaSession> {
  return request
    .post<App.ApiResponse<KlarnaCredentials>, KlarnaPayloadServer>(
      '/api/klarna/credentials',
      transformKlarnaPayloadToServer(data),
      { credentials: 'include' },
    ).then(response => ({
      clientToken: response.result.client_token,
      paymentMethod: response.result.payment_method_categories[0].identifier,
      id: response.result.session_id,
    }))
}

export function updateKlarnaSession(id: string, data: KlarnaPayloadClient): Promise<App.ApiResponse<void>> {
  return request
    .put<App.ApiResponse<void>, KlarnaPayloadServer>(
      `/api/klarna/session/${id}`,
      transformKlarnaPayloadToServer(data),
      { credentials: 'include' },
    )
}

export function getSavedCardsFromStripe(customer_id) {
  return request.get<App.ApiResponse<Array<any>>>(`/api/stripe/${customer_id}/cards`, { credentials: 'include' }).then(response => response.result)
}

export function getSavedCardsOnStripe(customer_id) {
  return request.get<App.ApiResponse<Array<App.StripePaymentCard>>>(`/api/stripe/${customer_id}/saved-cards`, { credentials: 'include' }).then(response => response.result)
}

export function getPaymentMethodFromStripe(payment_method_id: string, currency: string) {
  return request.get<App.ApiResponse<App.StripePaymentCard>>(`/api/stripe/payment-method/${payment_method_id}?currency=${currency}`, { credentials: 'include' }).then(response => response.result)
}

export function createPendingLatitudeTransaction(data) {
  return request.post('/api/latitude/checkout/initiate', data, { credentials: 'include' })
}

export function createStripeTransaction(data) {
  return request.post('/api/stripe/checkout', data, { credentials: 'include' })
}

export function createRazorpayTransaction(data) {
  return request.post('/api/razorpay/checkout', data, { credentials: 'include' })
}

export interface KlarnaTransactionRequestClient {
  authorization_token: string;
  currency: string;
  fk_orders: string;
  supplementary_data: KlarnaPayloadClient;
  item_ids: Array<string>;
}

export interface KlarnaTransactionRequestServer {
  authorization_token: string;
  currency: string;
  fk_orders: string;
  supplementary_data: KlarnaPayloadServer;
}

export interface KlarnaTransaction {
  clientToken: string;
  paymentMethod: string;
}

export function createKlarnaTransaction(data: KlarnaTransactionRequestClient): Promise<KlarnaTransaction> {
  return request
    .post<App.ApiResponse<KlarnaTransaction>, KlarnaTransactionRequestServer>(
      '/api/klarna/checkout',
      {
        ...data,
        supplementary_data: transformKlarnaPayloadToServer(data.supplementary_data),
      },
      { credentials: 'include' },
    ).then(response => response.result)
}

export function createPromoTransaction(data) {
  return request.post('/api/promo/checkout', data, { credentials: 'include' })
}

export function getLatitudeApplyRequest() {
  return request.get<App.ApiResponse<{ [key: string]: string }>>('/api/latitude/checkout/application-request', { credentials: 'include' })
}

export function resendConfirmationForTransaction(orderId: string, offerId?: string) {
  return request.post('/api/orders/' + orderId + '/resend', { id_offer: offerId }, {
    credentials: 'include',
  })
}

export function getAvailableCredit(memberId: string, currency = 'AUD') {
  const uri = `/api/credits?id_member=${memberId}&currency=${currency}`
  return request.get<App.ApiResponse<Array<unknown>>>(uri, { credentials: 'include' })
    .then(response => ({
      balance: parseFloat(response.metadata.available_credit),
      credits: response.result.map(creditMap),
      nextExpiringCredit: response.metadata.next_expiring,
    }))
}

export function getOrderItemFlightDetails({ orderId, itemId }) {
  return request.get<App.ApiResponse<any>>(`/api/orders/${orderId}/items/${itemId}/flight-details`, { credentials: 'include' })
    .then(resp => flightDetailsMap(resp.result))
}

function flightDetailsMap(details): App.FlightOrderItemDetails {
  return {
    journeyFares: {
      ...details.journey.journeyFares,
      fares: details.journey.journeyFares.fares.map(journeyV2Map),
    },
    fareFamily: details.journey.selectedUpsellOption,
    fareFamilies: details.journey.selectedUpsellOptions,
    reservationInfo: details.journey.reservationInfo,
    travellers: details.travellers.map(travellerMap),
    providerBookingReference: details.provider_booking_reference,
    pnrId: details.pnr_id,
    viewType: details.journey.viewType ?? details.view_type,
    region: details.region,
    fareType: details.journey.fareType as FareType,
  }
}

function travellerMap(traveller) {
  // TODO: do this better
  return traveller
}

export function transformKlarnaPayloadToServer(payload: KlarnaPayloadClient): KlarnaPayloadServer {
  return {
    attachment: payload.attachment,
    locale: payload.locale,
    purchase_country: payload.purchaseCountry,
    purchase_currency: payload.purchaseCurrency,
    // billing_address: {
    //   email: payload.billingAddress.email,
    //   family_name: payload.billingAddress.familyName,
    //   given_name: payload.billingAddress.givenName,
    //   phone: payload.billingAddress.phone,
    // },
    customer: {
      date_of_birth: payload.customer.dateOfBirth,
    },
    order_amount: payload.orderAmount,
    order_tax_amount: payload.orderTaxAmount,
    order_lines: payload.orderLines.map(line => ({
      type: line.type,
      reference: line.reference,
      name: line.name,
      quantity: line.quantity,
      unit_price: line.unitPrice,
      tax_rate: line.taxRate,
      total_amount: line.totalAmount,
      total_discount_amount: line.totalDiscountAmount,
      total_tax_amount: line.totalTaxAmount,
      product_url: line.productUrl,
      image_url: line.imageUrl,
    })),
  }
}

function datesRequestMap(datesRequest): App.DatesRequest {
  return {
    customerId: datesRequest.fk_customer_id,
    orderId: datesRequest.fk_order_id,
    itemId: datesRequest.fk_item_id,
    packageId: datesRequest.fk_package_id,
    vendorId: datesRequest.vendor_salesforce_id,
    roomName: datesRequest.room_name,
    requestDate: datesRequest.request_date,
    numberOfAdults: datesRequest.number_of_adults,
    numberOfInfants: datesRequest.number_of_infants,
    numberOfChildren: datesRequest.number_of_children,
    createdAt: datesRequest.created_at,
    updatedAt: datesRequest.updated_at,
    status: datesRequest.status,
    type: datesRequest.type,
  }
}

interface OrderRefundDetails {
  [id: string]: {
    refundAmount: number;
    refundFee: number;
  }
}

export function getOrderItemsRefundDetails(orderItemIds: Array<string>): Promise<Array<App.OrderItemRefundDetails>> {
  const query = qs.stringify({
    item_ids: orderItemIds.join(','),
  })
  return request.get<App.ApiResponse<Array<OrderRefundDetails>>>(`/api/orders/items/refund-policies?${query}`, { credentials: 'include' })
    .then(res => res.result.map(res => {
      const [orderItemId, details] = Object.entries(res)[0]
      return {
        ...details,
        orderItemId,
      }
    }))
}

export function getOrderItemRefundDetails(orderItemId: string): Promise<App.OrderItemRefundDetails> {
  return request.get<App.ApiResponse<OrderRefundDetails>>(`/api/orders/items/${orderItemId}/refund-policies`, { credentials: 'include' })
    .then(res => ({
      ...res.result[orderItemId],
      orderItemId,
    }))
}

export function redeemGiftRequest(code: string, orderId: string) {
  const uri = '/api/orders/redeem'
  return request.post<App.ApiResponse<OrderTypes.Order>, unknown>(uri, { id_order: orderId, redeem_code: code }, { credentials: 'include' })
    .then(response => orderMap(response.result))
}

export function markExperienceItemAsRedeemed(experienceItemId: string) {
  return request.patch(`/api/orders/experiences/items/booking-details/${experienceItemId}/mark-as-redeemed`, {}, { credentials: 'include' })
}

export function updateOrderPartnership({ orderId, type, accountId, lastName, firstName }) {
  return request.patch(`/api/orders/${orderId}?without_brand=1`, {
    op: 'update_order_partnership',
    partnership: {
      type,
      account_id: accountId,
      last_name: lastName,
      first_name: firstName,
    },
  }, { credentials: 'include' })
    .then(() => getOrderById(orderId)) // Patch does not return useful information hence an order refetch is required
}

export function getTaxInvoiceDetails(orderId: string): Promise<App.OrderTaxInvoiceDetails> {
  return request.get(`/api/orders/${orderId}/tax-invoice-details`, { credentials: 'include' })
}

interface RefundCustomOfferProps {
  orderId: string;
  itemId: string;
  method: App.RefundMethod;
  customerRefundReason: string;
}

interface RefundCustomOfferToOriginalProps extends Omit<RefundCustomOfferProps, 'method' | 'customerRefundReason'> {}

async function refundCustomOfferToOriginal({
  orderId,
  itemId,
}: RefundCustomOfferToOriginalProps) {
  // back to origin will not refund anything automatically
  const uri = `/api/orders/${orderId}/items/${itemId}/cancel`
  const data = {
    op: 'cancel_custom_order_self_service',
  }
  await request.post(uri, data, { credentials: 'include' })
}

async function refundCustomOfferToCredit({
  orderId,
  itemId,
  method,
  customerRefundReason,
}: RefundCustomOfferProps) {
  const uri = `/api/orders/${orderId}/items/${itemId}?room_id=null`
  let comment = REFUND_BACK_TO_ORIGIN_COMMENT
  if (customerRefundReason) {
    comment = REFUND_REASONS.find((r) => r === customerRefundReason) ?
      customerRefundReason :
      `Other - {${customerRefundReason}}`
  }
  const data: App.RefundRequestData = {
    op: 'refund',
    transaction_key: uuidV4(),
    reason: 'Custom Offer automatic refund',
    comment,
    method,
  }

  await request.put(uri, data, { credentials: 'include' })
}

export async function refundCustomOffers({
  orderId,
  itemId,
  method,
  customerRefundReason,
}: RefundCustomOfferProps): Promise<App.Order> {
  if (method === 'back_to_origin') {
    // this will create the salesforce case without
    await refundCustomOfferToOriginal({
      orderId,
      itemId,
    })
  } else {
    // this will refund the credits automatically
    await refundCustomOfferToCredit({
      orderId,
      itemId,
      method,
      customerRefundReason,
    })
  }
  // when we call refundCustomOffers, there is a slight delay between the update due to jobs being created.
  // So sometimes we call getOrderWithPaymentsAndReservations but some of these jobs could not be finished yet.
  // When this happens we need to wait a few moments and call getOrderWithPaymentsAndReservations again.
  // This function does it
  const updatedOrder = await asyncPoll<App.Order>({
    validateFunction: async(order) => {
      const item = order.customOfferItems.find(updatedItem => updatedItem.id === itemId)
      return !!item && item.status === 'cancelled'
    },
    apiCall: () => getOrderWithPaymentsAndReservations(orderId),
    maxTime: 30000,
    pollInterval: 500,
  })

  return updatedOrder
}
