import Caption from 'components/Luxkit/Typography/Caption'
import React from 'react'
import LuxPlusLogo from './Logos/LuxPlusLogo'
import { useAppSelector } from 'hooks/reduxHooks'
import { getAvailableBookingProtectionBenefits } from 'luxPlus/selectors/benefits/bookingProtection'
import FormatCurrency from 'components/Common/FormatCurrency'

interface Props {
  logoHeight: number
  saveAmount: number
}

function LuxPlusBookingProtectionLabelLabel(props: Props) {
  const { logoHeight, saveAmount } = props
  const luxPlusBookingProtectionBenefitView = useAppSelector(getAvailableBookingProtectionBenefits)
  const showBookingProtectionPercentage = saveAmount < luxPlusBookingProtectionBenefitView.luxPlusDiscountValueDisplayThreshold
  const displayValue = showBookingProtectionPercentage ? `up to ${luxPlusBookingProtectionBenefitView.luxPlusDiscountPercentage}%` : <FormatCurrency value={saveAmount} format="rounded"/>
  return <><Caption variant="medium" weight="bold">Save {displayValue} with </Caption><LuxPlusLogo height={logoHeight} /></>
}

export default LuxPlusBookingProtectionLabelLabel
