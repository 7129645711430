import { getLuxPlusSubscriptionItems } from 'checkout/selectors/view/luxPlusSubscription'
import { INTL_DMY_CASUAL_FORMAT } from 'constants/dateFormats'
import { intlFormatter } from 'lib/datetime/dateUtils'
import createSelector from 'lib/web/createSelector'

export const getLuxPlusSubscriptionRenewalDate = createSelector(
  (state: App.State) => state.loyalty.member.subscription.item?.expiryDate,
  (expiryDate): string | undefined => {
    if (!expiryDate) return undefined
    const renewalDate = new Date(expiryDate)
    return intlFormatter(renewalDate, INTL_DMY_CASUAL_FORMAT)
  },
)

export const getLuxPlusSubscriptionRenewalOrExpiryDate = createSelector(
  (state: App.State) => state.loyalty.member.subscription.item,
  getLuxPlusSubscriptionRenewalDate,
  (subscriptionItem, renewalDate): string | undefined => {
    const expiryDate = subscriptionItem?.expiryDate
    const hasActiveLuxPlusSubscriptionRenewal = subscriptionItem?.renewalStatus === 'ALLOW_RENEWAL'
    if (!renewalDate && !expiryDate) return undefined
    if (hasActiveLuxPlusSubscriptionRenewal) return renewalDate
    return intlFormatter(expiryDate!, INTL_DMY_CASUAL_FORMAT)
  },
)

const isMembershipSubscriptionItemSubscribedTier = (item: App.MemberSubscriptionItem): item is App.MemberSubscriptionItemSubscribedTier => {
  return item.tier !== 'free_preview'
}

export const prioritisedSubscriptionItem = createSelector(
  (state: App.State) => state.loyalty.member.subscription,
  getLuxPlusSubscriptionItems,
  (memberSubscription, checkoutSubscriptionItems): App.MemberSubscriptionItemSubscribedTier | App.Checkout.LuxPlusSubscriptionItem | undefined => {
    // We always want to prioritise the member subscription item if it exists
    // otherwise we use the first item in the cart isLuxPlusSubscriptionItem that comes from the subscription offer
    // NOTE - if free preview user we want to prioritise the checkoutSubscriptionItems
    return memberSubscription.item && isMembershipSubscriptionItemSubscribedTier(memberSubscription.item) ? memberSubscription.item : checkoutSubscriptionItems[0]
  },
)

export const getCurrentLoyaltyMemberTier = (state: App.State): App.MembershipTier | undefined => state.loyalty.member.subscription.item?.tier

export const getBaseTierOfferWithNoJoiningFee = createSelector(
  (state: App.State) => state.loyalty.products.subscription.offers,
  (loyaltyProductsSubscriptionOffers): App.SubscriptionOffer | undefined => loyaltyProductsSubscriptionOffers?.find(offer => offer.joinFeeWaived),
)

export const getBaseTierOfferWithJoiningFee = createSelector(
  (state: App.State) => state.loyalty.products.subscription.offers,
  (loyaltyProductsSubscriptionOffers): App.SubscriptionOffer | undefined => loyaltyProductsSubscriptionOffers?.find(offer => !offer.joinFeeWaived),
)
