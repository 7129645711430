import getValueOffPercent from 'lib/offer/getValueOffPercent'
import { OptimizelyExperiments } from 'constants/optimizely'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { getBundleValueUp } from 'lib/bundleAndSave/getBundleValueUp'
import { isBundleOffer, isCruiseOffer } from 'lib/offer/offerTypes'
import { addFlightPrice, divideAndCeilPricing } from 'lib/offer/pricing'

export interface PriceOptions {
  flightPrice?: number;
  numberOfNights?: number;
}

function packagePriceDefault(
  pkg: App.Package | App.BundlePackageWithPrice,
  offer: App.Offer | App.OfferSummary | App.BundleOffer | App.BundleOfferSummary,
): {
  default: App.PricingWithValue
  member: App.PricingWithValue | undefined
} {
  const propertyFees = pkg.propertyFees

  const price = pkg.price + propertyFees
  let value = pkg.value + propertyFees
  let discountPercent = getValueOffPercent(value, price)
  const memberPrice = pkg.memberPrice > 0 ? pkg.memberPrice + propertyFees : undefined
  const memberValue = pkg.memberValue > 0 ? pkg.memberValue + propertyFees : undefined
  const saleUnit = offer.saleUnit
  let memberPricing: App.PricingWithValue | undefined

  const fees: Array<App.PricingFee> = [{
    type: 'property',
    amount: pkg.propertyFees,
  }]

  if (memberPrice || memberValue) {
    memberPricing = {
      // Using || rather than ?? because memberPrice and memberValue can be 0, which should be considered as undefined
      price: memberPrice || price,
      value: memberValue || value,
      discountPercent: getValueOffPercent(memberValue || value, memberPrice || price),
      fees,
      saleUnit,
    }
  }

  if (isBundleOffer(offer) && 'packages' in pkg) {
    // TODO look into refactoring all logic into here if AB test is successful
    const bundleValue = getBundleValueUp(offer, pkg, false)
    discountPercent = bundleValue.hotelDiscountPercent ?? 0
    value = bundleValue.valuedUpTo
    if (memberPricing) {
      const memberBundleValue = getBundleValueUp(offer, pkg, true)
      memberPricing.discountPercent = memberBundleValue.hotelDiscountPercent ?? 0
      memberPricing.value = memberBundleValue.valuedUpTo
    }
  }

  return {
    default: {
      price,
      value,
      discountPercent,
      fees,
      saleUnit,
    },
    member: memberPricing,
  }
}

function ratePriceDefault(offer: App.BedbankOffer | App.BedbankOfferSummary | undefined, rate?: App.BedbankRate): App.PricingWithValue | undefined {
  const propertyFees = rate?.totals?.propertyFees ?? 0
  const hotelPrice = rate ? rate.totals.inclusive : offer?.sell?.price
  if (!hotelPrice) return
  const value = rate?.value ?? offer?.sell?.value ?? hotelPrice
  const price = hotelPrice + propertyFees

  const fees: Array<App.PricingFee> = [{
    type: 'property',
    amount: propertyFees,
  }]

  return {
    price,
    value,
    discountPercent: getValueOffPercent(value, price),
    fees,
    saleUnit: 'total',
  }
}

/**
 * Returns pricing for offer or package if supplied without taking into account occupancies/rooms
 * **CAN BE AFFECTED BY THE FOLLOWING EXPERIMENTS**
 *  - Experiment: cro_price_per_night
 */
export function useOfferPackagePrice(
  pkg: App.Package | App.BundlePackageWithPrice,
  offer: App.Offer | App.OfferSummary | App.BundleOffer | App.BundleOfferSummary,
  options?: PriceOptions,
): {
  default: App.PricingWithValue
  member?: App.PricingWithValue
  withFlights?: App.PricingWithValue
  memberWithFlights?: App.PricingWithValue
} {
  const variantPerNight = useOptimizelyExperiment(OptimizelyExperiments.pricePerNight)
  const basePriceDetails = packagePriceDefault(pkg, offer)
  const numberOfNights = pkg.duration || offer.minDuration

  return variantPerNight && !offer.holidayTypes?.includes('Cruises') && !isCruiseOffer(offer) && typeof numberOfNights === 'number' ?
      {
        default: divideAndCeilPricing(basePriceDetails.default, numberOfNights, 'night'),
        member: basePriceDetails.member ? divideAndCeilPricing(basePriceDetails.member, numberOfNights, 'night') : undefined,
      } :
    addFlightPriceVariants(basePriceDetails, options?.flightPrice)
}

/**
 * Returns pricing for bedbank offer or rate if supplied without taking into account occupancies/rooms
 * **CAN BE AFFECTED BY THE FOLLOWING EXPERIMENTS**
 *  - Experiment: cro_price_per_night
 */
export function useOfferRatePrice(
  offer: App.BedbankOffer | App.BedbankOfferSummary | undefined,
  rate?: App.BedbankRate,
  options?: PriceOptions,
): {
  default?: App.PricingWithValue
  withFlights?: App.PricingWithValue
} {
  const variantPerNight = useOptimizelyExperiment(OptimizelyExperiments.pricePerNight)
  const basePriceDetails = ratePriceDefault(offer, rate)
  if (!basePriceDetails) return {}
  const numberOfNights = rate?.nights ?? offer?.sell?.los
  return variantPerNight && numberOfNights ? {
    default: divideAndCeilPricing(basePriceDetails, numberOfNights, 'night'),
  } : addFlightPriceVariants({
    default: basePriceDetails,
  }, options?.flightPrice)
}

function addFlightPriceVariants(pricing: {
  default: App.PricingWithValue
  member?: App.PricingWithValue
}, flightPrice?: number): {
  default: App.PricingWithValue
  member?: App.PricingWithValue
  withFlights?: App.PricingWithValue
  memberWithFlights?: App.PricingWithValue
} {
  if (!flightPrice) {
    return pricing
  }
  return {
    ...pricing,
    withFlights: addFlightPrice(pricing.default, flightPrice),
    memberWithFlights: pricing.member ? addFlightPrice(pricing.member, flightPrice) : undefined,
  }
}
